import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Layout from 'components/Layout/Layout';

import Illustration1 from '../assets/FAQ/RESPONSIVE/Resp_IllustrationFAQ.svg';
import Illustration1_Desktop from '../assets/FAQ/IllustrationFAQ.svg';
import Carousel from 'components/Carousel/Carousel';
import Card from 'components/Carousel/Card';

const FaqPage = () => {
  const famousSubjects = [
    {
      label: 'Sujet 1',
      link: '/faq'
    },
    {
      label: 'Sujet 2',
      link: '/faq'
    },
    {
      label: 'Sujet 3',
      link: '/faq'
    },
  ];

  const frequentlyAskedQuestions = [
    {
      title: 'Pourquoi participer à cette étude ? ',
      content: 'L’objectif de cette étude est de comprendre votre perception des traitements de la sclérose en plaques au-delà de leur efficacité clinique. Elle s’intéressera également à d’autres dimensions, comme le fardeau lié au traitement, le processus de décision partagée de ce traitement ainsi que les effets de la sclérose en plaques sur votre qualité de vie et votre fatigue. Nous recueillerons des informations sur votre parcours de soins et vos habitudes de vie liés à la sclérose en plaques ainsi que les éléments qui ont conduit au choix de votre traitement actuel.'
    },
    {
      title: 'Comment se déroulera l’étude ?',
      content: 'Cette étude est un questionnaire anonyme à compléter par vous-même en ligne sur un site 100% sécurisé. Votre participation ne changera ni la fréquence de vos visites chez votre médecin, ni les soins cliniques que vous recevez actuellement, et aucune visite ou examen supplémentaires ne seront requis. Vous n’aurez pas à créer de compte. Vous n’aurez à aucun moment à saisir d’informations qui pourraient vous identifier directement (nous ne vous demanderons ni votre nom, ni votre prénom, ni de moyen de contact) : nous ne collectons que les données utiles à la réponse aux questions scientifiques posées. Vos réponses resteront pseudonymisées.'
    },
    {
      title: 'Qu’en est-il de mon traitement ?',
      content: 'Cette étude a lieu dans le cadre de votre soin courant. Votre participation ne changera ni la fréquence de vos visites chez votre médecin, ni les soins cliniques que vous recevez actuellement, et aucune visite ou examen supplémentaires ne seront requis. Vous devrez seulement répondre à un questionnaire en ligne. Si vous souhaitez déclarer certains effets secondaires de vos traitements, merci d’en discuter avec votre médecin ou de vous référer au site de <a href="https://google.fr">l’ANSM - Comment déclarer un effet indésirable</a> .'
    },
    {
      title: 'J\'ai une question, où puis-je la poser ?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus corrupti, delectus neque minima, odit, vel nihil quas earum assumenda beatae porro dolorum itaque veritatis reprehenderit unde? Minus ipsam temporibus distinctio perferendis ex repellat animi dignissimos, maxime corporis? Repellendus, aliquid necessitatibus aperiam dolor ipsam fuga provident quasi vitae voluptatum, numquam odit.'
    },
  ];

  const randomColor = (): string => {
    const colors = ["#E29578", "#FFDDD2", "#679ACB"];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  return (
    <Layout title="FAQ">
      <img src={Illustration1} alt="Illustration 1" className="absolute top-10 right-0 md:w-36 lg:hidden" />
      <img src={Illustration1_Desktop} alt="Illustration 1" className="hidden absolute top-10 right-0 w-72 lg:block" />
      <h1 className="text-xl sm:text-2xl md:text-3xl mt-48 md:mt-64 font-bold text-center">
        Comment pouvons-nous <br /> vous aider ?
      </h1>
      <section className="bg-dark-blue py-12 rounded-xl my-16 lg:-mb-5 lg:pb-20 md:mt-36 flex flex-col items-center overflow-hidden">
        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-white text-center relative z-10">
          Questions fréquentes
        </h2>
        <hr className="border-0 w-56 sm:w-64 md:w-80 h-3 md:h-4 rounded-full mx-auto bg-dark-pink transform -translate-y-3 mb-12" />
        <Carousel>
          {frequentlyAskedQuestions.map(question => <Card title={question.title} content={question.content}/>)}
        </Carousel>
        {/*<ul className="hidden lg:grid grid-cols-3 gap-10 px-10 max-w-[1280px]">
          {frequentlyAskedQuestions.map(question => (
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">{question.title}</h3>
              <p className="text-jsutify">{question.content}</p>
            </li>
          ))}
          </ul>*/}
        <ul className="hidden lg:grid grid-cols-3 gap-10 px-10 max-w-[1280px]">
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Pourquoi participer à cette étude ?</h3>
              <p className="text-jsutify">L’objectif de cette étude est de comprendre <strong>votre perception des traitements</strong> de la sclérose en plaques au-delà de leur efficacité clinique. Elle s’intéressera également à d’autres dimensions, comme <strong>le fardeau lié au traitement, le processus de décision partagée</strong> de ce traitement ainsi que les effets de la sclérose en plaques sur <strong>votre qualité de vie</strong> et <strong>votre fatigue</strong>. Nous recueillerons des informations sur votre parcours de soins et vos habitudes de vie liés à la sclérose en plaques ainsi que les éléments qui ont conduit au choix de votre traitement actuel.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Comment se déroulera l’étude ?</h3>
              <p className="text-jsutify">Cette étude est un <strong>questionnaire anonyme</strong> à compléter <strong>par vous-même en ligne</strong> sur un site 100% sécurisé. Votre participation <strong>ne changera ni la fréquence de vos visites chez votre médecin, ni les soins cliniques que vous recevez actuellement, et aucune visite ou examen supplémentaires ne seront requis.</strong><br /><br />Vous <strong>n’aurez pas à créer de compte</strong>. Vous n'aurez pas à créer de compte. Vous n’aurez à aucun moment à saisir d’informations qui pourraient vous identifier directement (nous ne vous demanderons ni votre nom, ni votre prénom, ni de moyen de contact) : nous ne collectons que les données utiles à la réponse aux questions scientifiques posées. Vos réponses resteront pseudonymisées.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Qu’en est-il de mon traitement ?</h3>
              <p className="text-jsutify">Cette étude a lieu dans le cadre de votre soin courant. Votre participation <strong>ne changera ni la fréquence de vos visites chez votre médecin, ni les soins cliniques que vous recevez actuellement, et aucune visite ou examen supplémentaires ne seront requis</strong>. Vous devrez seulement répondre à un questionnaire en ligne.<br />Si vous souhaitez déclarer certains effets secondaires de vos traitements, merci d’en discuter avec votre médecin ou de vous référer au site de l'<a href="https://ansm.sante.fr/documents/reference/declarer-un-effet-indesirable" style={{ color: "blue"}}>ANSM - Comment déclarer un effet indésirable</a>.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Puis je participer à cette étude ?</h3>
              <p className="text-jsutify">Vous êtes diagnostiqué(e) d’une sclérose en plaques et ce diagnostic a été effectué par un neurologue.<br /><br />•	Vous avez plus de 18 ans.<br />•	Vous êtes suivi(e) par un neurologue en France<br />•	Vous recevez l’un des traitements suivants :<br />&nbsp;&nbsp;&nbsp;o Ofatumumab (Kesimpta®)<br />&nbsp;&nbsp;&nbsp;o Ocrelizumab (Ocrevus®)<br />&nbsp;&nbsp;&nbsp;o Natalizumab (Tysabri®) en administration intraveineuse (IV)<br />&nbsp;&nbsp;&nbsp;o Natalizumab (Tysabri®) en administration sous-cutanée (SC)<br />• Vous recevez ce traitement depuis plus de 6 mois. </p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Combien de temps dure le questionnaire en ligne ?</h3>
              <p className="text-jsutify">Nous estimons que le remplissage de ce questionnaire vous prendra <strong>environ 30 min</strong>.<br />Mais vous pouvez faire une pause tout en sauvegardant votre questionnaire pour le compléter en deux temps. </p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Comment faire une pause dans mon questionnaire ?</h3>
              <p className="text-jsutify">Nous anticipons la possibilité que le questionnaire puisse être suffisamment long pour que vous ayez besoin/envie de répondre en plusieurs fois. Mettre en pause le questionnaire et le reprendre après est tout à fait possible. Pour cela :<br /><br />1.	Un <strong>code</strong> sera affiché sur votre écran lors de la complétion du questionnaire.<br />2. Vous pouvez, selon votre choix : le noter ou vous l’envoyer par e-mail.<br />3. Lors de votre prochaine visite il faudra utiliser ce code dans la section dédiée (<strong>Reprendre mon formulaire</strong>) pour reprendre le questionnaire là où vous l’aviez quitté.<br /><br />Uniquement dans le cas où vous choisiriez l’envoi par mail, votre e-mail ne sera stocké que temporairement sur nos serveurs, le temps de son envoi. Une fois le courrier envoyé, l'adresse email sera aussitôt supprimée de nos serveurs. Il ne sera donc pas possible d’associer l’adresse email (donnée personnelle directement identifiante) aux données de santé collectées. </p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Quelles questions me seront posées ?</h3>
              <p className="text-jsutify">Vous allez pouvoir vous exprimer librement sur la valeur ajoutée et votre ressenti de votre traitement actuel au-delà de leur efficacité clinique. Nous allons également vous poser des questions sur l’impact de la sclérose en plaques sur votre qualité de vie et sur votre fatigue. Enfin, il y aura quelques questions sur vous et ce qui a entraîné le choix de votre traitement actuel lors de la discussion avec votre médecin.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Puis-je partager mon questionnaire ?</h3>
              <p className="text-jsutify">Cette étude est une recherche biomédicale conçue pour pouvoir y répondre par vous-même. Cependant, vous pourrez partager le questionnaire avec votre médecin avant de débuter votre participation, vous avez accès à la <strong>version imprimable du questionnaire</strong>. Vous pourrez également vous faire aider par une personne de votre entourage ou un aidant pour compléter ces questions.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Puis - je poser des questions ?</h3>
              <p className="text-jsutify">Un espace de <strong>support utilisateur</strong> est disponible sur la plateforme. Cet espace vous permet de poser des questions sur l’étude, le questionnaire ou bien d’exercer vos droits (Section : <strong>Comment exercer vos droits ?</strong>). Il vous suffit de rentrer le titre de votre demande et sa description.<br /><br />Attention cependant, cette étude étant <strong>anonyme</strong>, nous ne collectons aucune donnée directement identifiante. Nous <strong>ne pourrons donc pas vous répondre individuellement</strong>. Seul des <strong>réponses génériques</strong> seront fournies régulièrement sur cette FAQ.   Les questions liées à l’étude seront revues par l’investigateur principal, le Professeur Vermersch, avant d’être publiée sur le site.<br /><br />Seules les questions relatives à l’étude seront prises en compte. Pour toute question concernant votre maladie ou votre traitement merci de vous référer à votre médecin traitant.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Qui est promoteur de l'étude ?</h3>
              <p className="text-jsutify">New perSEPtion est initiée et financée par Biogen France SAS, 1 passerelle des Reflets, Tour CBX, 92913 Paris La Défense Cedex, laboratoire pharmaceutique, et conduite par SKEZI.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Où sont stockées mes données ?</h3>
              <p className="text-jsutify">En France.<br />Cette étude est entièrement décentralisée et donc n'est basée dans aucun lieu mais sur un serveur privé qui collecte les données du questionnaire. Vos données seront collectées en conformité des règles locales et selon les principes éthiques qui ont leur origine des principes de la déclaration d’Helsinki.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Quelles sont les données recueillies ?</h3>
              <p className="text-jsutify">Aucune donnée personnelle permettant de vous identifier directement ne sera collectée pour cette étude. Nous ne collecterons par votre nom, votre prénom, votre adresse postale ou adresse-email. Nous collecterons uniquement des informations sur l’impact de la maladie sur votre vie personnelle – qualité de vie, sur la fatigue ainsi que sur votre perception des traitements, leurs fardeaux et ce qui a mené à leur choix. Nous récolterons aussi quelques informations sur vous (sexe, âge, département de résidence) et votre maladie (année de diagnostic et début de traitement, date de dernière injection et type de structure de suivi).</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Qui aura accès à mes données ?</h3>
              <p className="text-jsutify">Biogen France SAS et son-traitant SKEZI SAS. Le responsable de traitement Biogen France SAS a la responsabilité de s'assurer que vos données personnelles sont traitées conformément à la loi (le Règlement général sur la protection des données 2016/679 et la loi française n°2018-493 du 20 juin 2018 relative à la protection des données personnelles). Toutes les informations vous concernant seront traitées de manière confidentielle et pseudonymisée, c'est- à-dire sans aucune information directe permettant de vous identifier.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Que deviendront mes données après l’étude ?</h3>
              <p className="text-jsutify">Vos données seront seulement utilisés pour répondre aux objectifs de l’étude et jamais pour d’autres finalités. Les résultats de l’enquête feront l’objet de publications scientifiques et/ou de communications dans des congrès scientifiques. Elles seront conservées sur un serveur sécurisé jusqu’à 2 ans après la publication des résultats, conformément à la méthodologie de référence MR_003 de la CNIL, et seront par la suite archivées pour une durée maximale de 20 ans par le promoteur de l’étude.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Qu’adviendra-t- il des résultats de cette étude ?</h3>
              <p className="text-jsutify">Une description de cette étude sera disponible sur le site internet www.clinicaltrials.gov. Ce site Web n’inclura pas d’informations permettant de vous identifier directement. Tout au plus, le site Web comprendra un résumé des résultats. Ces données sont anonymes. Les résultats de cette étude pourront être publiés dans des journaux scientifiques ou présentés dans des congrès scientifiques mais aucune information permettant de vous identifier directement ne sera incluse.</p>
            </li>
            <li className="bg-pastel-blue rounded-xl p-5 relative">
              <div className="absolute top-10 bottom-10 left-0 transform -translate-x-1/2 w-5 rounded-full" style={{ backgroundColor: randomColor() }} />
              <h3 className="text-lg font-bold mb-5">Comment exercer mes droits ?</h3>
              <p className="text-jsutify">Votre participation à cette étude est <strong>entièrement libre et volontaire</strong>. Si vous souhaitez exercer vos droits conformément au Règlement Général sur la Protection des Données (RGPD), vous pouvez à tout moment demander à :<br />• consulter vos données personnelles ;<br />• les faire modifier ;<br />• limiter le traitement de certaines données<br /><br />A la fin de votre questionnaire, votre numéro de pseudonymisation s’affichera. Il vous sera propre et sera attaché à vos données. Si vous souhaitez exercer vos droits, il faudra nous communiquer ce code unique. Pour cela :<br /><br />1.	Le <strong>code</strong> sera affiché sur votre écran lors de la complétion du questionnaire.<br />2.	Si vous souhaitez exercer vos droits ultérieurement, vous devrez selon votre choix : noter ce numéro ou vous l’envoyer par e-mail.<br />3.	Lors de votre prochaine visite, cliquez sur l’onglet de <strong>support utilisateur</strong> de la plateforme :<br />&nbsp;&nbsp;&nbsp;a.	Objet : Indiquez l’objet de votre demande<br />&nbsp;&nbsp;&nbsp;b.	Description : Décrivez votre demande en indiquant votre code unique à 6 chiffres associé à votre questionnaire.<br /><br />Uniquement dans le cas où vous choisiriez l’envoi par mail, votre e-mail ne sera stocké que temporairement sur nos serveurs, le temps de son envoi. Une fois l’email envoyé, l'adresse email sera aussitôt supprimée de nos serveurs. Il ne sera donc pas possible d’associer l’adresse email (donnée personnelle directement identifiante) aux données de santé collectée.<br /><br />Si vous souhaitez arrêter votre participation, veuillez noter que, dans ce cas, les données personnelles collectées avant votre retrait peuvent encore être traitées avec les autres données collectées dans le cadre de l’étude. Le droit à l’effacement et le droit à la portabilité ne sont pas applicables à ce traitement.</p>
            </li>
        </ul>
      </section>
    </Layout>
  );
}

export default FaqPage;