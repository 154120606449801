/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  Model,
  CustomWidgetCollection,
  StylesManager,
} from 'survey-core';
import { Survey } from 'survey-react';
import { SurveyPDF, SurveyHelper } from 'survey-pdf';
import { toast } from 'react-toastify';

import Layout from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import ModalSaveSurvey from 'components/Modals/ModalSaveSurvey';
import SubmitButton from 'components/Buttons/SubmitButton';
import ResponseService from 'services/response.service';
import DeviceDetector from 'device-detector-js';

import 'survey-core/survey.i18n';
import 'survey-react/defaultV2.min.css';
import '../styles/Survey.css';

import { Piechart } from 'widgets/piechart/piechart';

StylesManager.applyTheme('defaultV2');
SurveyHelper.HTML_TO_IMAGE_QUALITY = 4.0;

function SurveyWrapper({ survey }: any) {
  return <Survey model={survey} />;
}

CustomWidgetCollection.Instance.addCustomWidget(Piechart, 'customtype');

const MemoizedSurveyWrapper = React.memo(SurveyWrapper);

const SurveyPage = () => {
  const [surveyData, setSurveyData] = useState();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [code, setCode] = useState<string>();
  const [responsesSaved, setResponsesSaved] = useState<{ json: object, page: any }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingRecovery, setLoadingRecovery] = useState<boolean>(false);
  const [loadingSaving, setLoadingSaving] = useState<boolean>(false);

  const [codeRecoveryInput, setCodeRecoveryInput] = useState<string>('');
  const [emailInput, setEmailInput] = useState<string>('');

  const [timeOneQuestionChange, setTimeOneQuestionChange] = useState<{
    name: string;
    value: number;
  }>();
  const [timePerQuestion, setTimePerQuestion] = useState<{
    [key: string]: number;
  }>({});
  let lastKeypressTime: number | null;
  const stateRef = useRef({ timePerQuestion: {} });
  stateRef.current = { timePerQuestion };

  const setTitle = (text: string) => {
    const title = document.querySelector('h3.sd-title > span.sv-string-viewer');
    return title ? title.textContent = text : null;
  }

  const deleteTitle = () => document.querySelector('h4.sd-title.sd-page__title')?.remove();

  const setImage = (page: number) => {
    document.querySelector('.sd-logo__image')?.setAttribute('src', `/SURVEY/Page${page.toString()}.svg`);
  }

  const handlePiechartValidation = (
    _: any,
    options: { name: string; value: { proportion: number }[]; error: string },
  ) => {
    if (
      [
        'traitement_temps',
      ].includes(options.name)
    ) {
      let response: { proportion: number }[] = Object.values(options.value);
      let totalProportion: number = response.reduce(
        (acc: number, data: { proportion: number }) => acc + data.proportion,
        0,
      );

      if (totalProportion !== 1) {
        options.error = "La jauge n'est pas remplie à 100%.";
      }
    }
  };

  const onPageChange = useCallback(async (survey: any) => {
    //setCurrentPage(survey.currentPageNo);
    //setTimeout(() => window.scrollTo(0, 0), 100);
    //setTimeout(() => setCurrentPage(survey.currentPageNo), 500);
  }, []);

  const onComplete = useCallback(async (survey: any) => {
    survey.stopTimer();

    let timePerPage = {};
    survey.pages.forEach((page: any) => {
      timePerPage = { ...timePerPage, [page.name]: page.timeSpent };
    });


    const responseService = new ResponseService();

    await responseService.addResponse({
      data: survey.data,
      ...code ? { code: code } : {},
      timeSpent: survey.timeSpent,
      timePerPage,
      timePerQuestion: stateRef.current.timePerQuestion,
      state: 'COMPLETED'
    });
  }, [code]);


  const sendMail = async (e: any) => {
    e.preventDefault();
    if (code) {
      try {
        setLoadingSaving(true);
        const responseService = new ResponseService();

        await responseService.sendMail({
          email: emailInput,
          code: code
        });
        toast.success('Un mail vous a été envoyé avec un code de sauvegarde !');
        setIsModalVisible(false);
        setEmailInput('');
        setLoadingSaving(false);
      } catch (err) {
        console.error(err);
        setLoadingSaving(false);
      }
    }
  }

  const onRecovery = async (e: HTMLFormElement) => {
    e.preventDefault();
    setLoadingRecovery(true);

    const responseService = new ResponseService();

    try {
      const result = await responseService.loadResponses(codeRecoveryInput);

      if (result.status === 404) {
        toast.error('Le code ne correspond à aucun questionnaire');
        setLoadingRecovery(false);
        return;
      }
      setCode(codeRecoveryInput);
      setResponsesSaved(result);
      setIsModalVisible(false);
      setCodeRecoveryInput('');
      toast.success('Vos réponses ont bien été récupérées !');
      setLoadingRecovery(false);
    } catch (err) {
      toast.error('Le code ne correspond à aucun questionnaire');
      setLoadingRecovery(false);
    }
  };

  const onDownloadPDF = async () => {
    setLoading(true);
    const surveyPdf = new SurveyPDF(surveyData, { applyImageFit: true });
    surveyPdf.haveCommercialLicense = true;
    surveyPdf.data = survey.data;
    await surveyPdf.save();
    setLoading(false);
  }

  useEffect(() => {
    async function initSurvey() {
      const responseService = new ResponseService();
      const surveyJSON = await responseService.loadSurvey();
      setSurveyData(surveyJSON);
    }
    initSurvey();
  }, []);

  useEffect(() => {
    deleteTitle();
    if (survey.pages[currentPage]?.title)
      setTitle(survey.pages[currentPage].title);
    switch (currentPage) {

      case 0:
      case 1:
        setImage(1);
        break;

      case 2:
        setImage(2);
        break;

      case 3:
        setImage(3);
        break;

      case 4:
        setImage(4);
        break;

      case 5:
        setImage(5);
        break;

      case 6:
        setImage(6);
        break;

      case 7:
        setImage(7);
        break;

      case 8:
        setImage(8);
        break;

      default:
        setImage(8);
        break;
    }
  }, [currentPage, surveyData]);

  const survey = useMemo(() => {
    const survey = new Model(surveyData);

    survey.stopTimer();
    survey.startTimer();

    survey.locale = 'fr';

    survey.onAfterRenderPage.add(onPageChange);
    survey.onValidateQuestion.add(handlePiechartValidation);

    survey.locale = 'fr';

    const questionStartTimes: { [key: string]: number } = {};

    // Initialiser les heures de début pour toutes les questions
    for (const q of survey.getAllQuestions()) {
      questionStartTimes[q.name] = new Date().getTime();
    }

    const domElementAlreadyCheck: { [key: string]: boolean } = {};
    let lastKeypressTime: number | null;

    survey.onFocusInQuestion.add(function (sender, options) {
      if (!domElementAlreadyCheck[options.question.id]) {
        const surveyQuestionDomElement = document.getElementById(options.question.id);
        if (surveyQuestionDomElement) {
          surveyQuestionDomElement.querySelectorAll('input[type=text], input[type=number], textarea').forEach((textDomElement) => {
            textDomElement.addEventListener('keypress', function (e) {
              lastKeypressTime = new Date().getTime();
            });
          });
        }

        domElementAlreadyCheck[options.question.id] = true;
      }
    });

    const updateTimeQuestion = function (sender: any, questionName: any) {
      const currentTime = lastKeypressTime ? lastKeypressTime : new Date().getTime();

      if (lastKeypressTime) {
        lastKeypressTime = null;
      }

      if (questionStartTimes[questionName]) {
        const timeSpent = Math.round(
          (currentTime - questionStartTimes[questionName]) / 1000,
        );

        setTimeOneQuestionChange({
          name: questionName,
          value: timeSpent,
        });

        if (timeSpent !== 0) {
          // Mettre à jour les heures de début pour toutes les questions
          for (const q of sender.getAllQuestions()) {
            questionStartTimes[q.name] = currentTime;
          }
        }
      }
    }

    survey.onValueChanging.add(function (sender, options) {
      updateTimeQuestion(sender, options.name);
    });

    if (survey.hasCookie) setTimeout(() => setImage(8), 100);

    return survey;
  }, [surveyData]);

  useEffect(() => {
    console.log(responsesSaved);
    if (responsesSaved) {
      console.log("ouiiii");
      survey.firstPageIsStarted = false;
      survey.data = responsesSaved.json;
      survey.currentPage = responsesSaved.page;
    }
  }, [survey, responsesSaved]);

  const onValueChanged = useCallback(async (sender: any, options: any) => {
    const responseService = new ResponseService();

    try {

      let timePerPage = {};
      survey.pages.forEach((page) => {
        timePerPage = { ...timePerPage, [page.name]: page.timeSpent };
      });

      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);

      const returnCode = await responseService.addPartialResponse({
        data: survey?.data,
        ...code ? { code: code } : {},
        timeSpent: survey?.timeSpent,
        timePerPage,
        timePerQuestion: stateRef.current.timePerQuestion,
        lastQuestionAnswer: options.question.name,
        browser: device.client?.name,
        os: device.os?.name,
        deviceType: device.device?.type,
      });

      setCode(returnCode);

      /*setResponsesSaved({
        json: survey.data,
        page: survey.getQuestionByName(options.question.name).page.name
      });*/
    } catch (err) {
      console.error(err);
    }
  }, [survey, code]);

  useEffect(() => {
    survey.onValueChanged.clear();
    survey.onValueChanged.add(onValueChanged);
    survey.onComplete.clear();
    survey.onComplete.add(onComplete);
  }, [survey, onValueChanged, onComplete]);

  useEffect(() => {
    if (timeOneQuestionChange) {
      setTimePerQuestion((prev) => ({ ...prev, [timeOneQuestionChange.name]: timeOneQuestionChange.value + (timePerQuestion.hasOwnProperty(timeOneQuestionChange.name) ? timePerQuestion[timeOneQuestionChange.name] : 0) }));
    }
  }, [timeOneQuestionChange]);

  return (
    <Layout title="Participer à l'étude">
      <ModalSaveSurvey
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        codeRecovery={codeRecoveryInput}
        setCodeRecovery={setCodeRecoveryInput}
        submitRecovery={onRecovery}
        loadingRecovery={loadingRecovery}
        email={emailInput}
        setEmail={setEmailInput}
        loadingSaving={loadingSaving}
        sendMail={sendMail}
      />
      {
        surveyData ? (
          <div className="mt-36 mb-8">
            <div className="mb-8">
              <MemoizedSurveyWrapper survey={survey} />
            </div>
            <div className="flex justify-between px-4 sm:px-10">
              <button className="flex items-center space-x-5" onClick={onDownloadPDF}>
                {
                  loading ? (
                    <Loader />
                  ) : (
                    <>
                      <i className="fa-solid fa-download text-xl bg-dark-pink rounded-lg flex items-center justify-center px-3 py-2 text-white" />
                      <u className="text-dark-pink text-lg">Télécharger</u>
                    </>
                  )
                }
              </button>

              <button className="flex items-center space-x-5" onClick={() => setIsModalVisible(true)}>
                <u className="text-dark-pink text-lg">Récupérer mon questionnaire</u>
                <i className="fa-solid fa-floppy-disk text-xl bg-dark-pink rounded-lg flex items-center justify-center px-3 py-2 text-white" />
              </button>
            </div>
            {code &&
              <div className="flex items-center justify-center mx-12 my-12">
                <div className="inline-flex flex-col p-4 rounded-lg bg-[#FFDDD2]">
                  <p className="text-center">
                    Votre code : {code}
                  </p>
                  <br />
                  <p className="text-center">
                    Le code de sauvegarde vous servira à reprendre votre questionnaire où vous vous en êtes arrêté.
                    <span className="font-bold">Vous pouvez le noter et/ou vous l’envoyer par mail.</span>
                  </p>
                  <br />
                  <p className="text-center">
                    Ce code servira également si vous souhaitez revenir plus tard pour exercer vos droits - <a style={{ color: "blue" }} href="/faq">FAQ</a>.
                  </p>
                  <form onSubmit={sendMail}>
                    <div className="flex flex-col space-y-2 mt-7">
                      <input
                        onChange={e => setEmailInput(e.target.value)}
                        value={emailInput}
                        placeholder="Votre adresse email"
                        type="email"
                        name="email"
                        id="email"
                        className="border border-dark-pink px-4 py-2 outline-none focus:ring-2 ring-light-pink rounded"
                      />
                    </div>

                    <div className="mt-10 flex items-center justify-center">
                      {
                        loadingSaving ? (
                          <Loader />
                        ) : (
                          <SubmitButton text="Envoyer mon code" />
                        )
                      }
                    </div>
                  </form>
                </div>
              </div>
            }
          </div>
        ) : (
          <div className="mt-46 mb-36 text-center">
            <Loader />
          </div>
        )
      }
    </Layout>
  );
}

export default SurveyPage;