import Layout from 'components/Layout/Layout';

import '../styles/CGU.css';

const CGUPage = () => {
    return (
        <Layout title="CGU">
            <div className="cgu-paragraph mx-auto my-20 px-5 font-arimo">
                <h1 className="text-xl mt-40 md:text-3xl font-bold mb-10 md:mb-16">Conditions Générales d'Utilisation de la plateforme New PerSEPtion</h1>
                <p className="cgu-paragraph">
                    Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont pour objet de définir vos droits et obligations dans le cadre de votre participation à l’étude dénommée New PerSEPtion en tant qu’utilisateurs (ci-après « les Utilisateurs » ou « l’Utilisateur ») de la plateforme New PerSEPtion  (ci-après la « Plateforme »), de définir les conditions générales d’accès et d’utilisation de la Plateforme par tout Utilisateur et de rappeler les dispositions légales et règlementaires en vigueur.<br /><br />

                    Cette étude est mise en œuvre par la société Biogen France SAS inscrite au répertoire SIREN sous le numéro 398410126, dont le siège social est 1 PASSERELLE DES REFLETS 92400 COURBEVOIE. Biogen France SAS pourra modifier à tout moment les présentes Conditions Générales d'Utilisation, après information de l’Utilisateur par la mise en ligne de la nouvelle version des Conditions Générales d’Utilisation et acceptation préalable de l’Utilisateur pour utiliser la Plateforme. <br /><br />

                    Cette Plateforme est gérée par la société SKEZI. Elle est réservée aux Utilisateurs sélectionnés pour participer à l’étude dénommée New perSEPtion
                </p>

                <h2 className="cgu-title">1. Objet</h2>
                <p className="cgu-paragraph">
                    La Plateforme est une solution SaaS permettant le recueil de données de patients en vue de la réalisation d’une étude de santé nécessitant cette collecte, dans le cadre du parcours de soin et/ou à des fins de recherche scientifique.<br /><br />

                    L’objectif de cette Plateforme est de permettre la mise en œuvre d’une enquête, constituée de participants atteint de sclérose en plaques, afin de répondre à des questions de recherche dans le domaine thérapeutique des maladies chroniques.<br /><br />

                    La Plateforme est à l’usage personnel des Utilisateurs et ne peut pas être utilisée pour toute autre finalité.<br /><br />

                    L’objectif de cette étude est de comprendre la perception de l’Utilisateur vis-à-vis des traitements de la sclérose en plaques, au-delà de leur efficacité clinique. Elle s’intéressera également à d’autres dimensions, comme les effets de la sclérose en plaques sur la qualité de vie et la fatigue, le fardeau lié au traitement et enfin le processus de décision partagée du traitement.<br /><br />

                    Les présentes CGU ont pour objet de déterminer les règles et les modalités d'utilisation de la Plateforme.
                </p>

                <h2 className="cgu-title">2. Conditions d’accès et d’utilisation de la Plateforme</h2>
                <p className="cgu-paragraph">
                    La Plateforme est accessible via le lien https://new-perseption.skezi.eu/home<br /><br />

                    Pour accéder à la Plateforme, l’Utilisateur répond directement au questionnaire dès lors qu’il a pris connaissance de la notice d’information.<br /><br />

                    L’Utilisateur ne doit pas créer de compte personnel ni renseigner de données personnelles pour accéder au questionnaire.<br /><br />

                    L’Utilisateur garantit que les données qu'il renseigne sont exactes et conformes à la réalité.<br /><br />

                    Une notice d’information relative à l’étude est portée à la connaissance de l’Utilisateur avant sa participation à l’étude. Si l’Utilisateur n’en prend pas connaissance, il ne sera pas autorisé à participer à l’étude.<br /><br />

                    L’Utilisateur a la possibilité d’imprimer à tout moment le questionnaire.<br /><br />

                    L’Utilisateur a également la possibilité de compléter en plusieurs fois le questionnaire via un code. Lorsque qu’il souhaite enregistrer son questionnaire l’utilisateur reçoit un code qu’il pourra noter et/ou se l’envoyer par mail. S’il choisit l’option mail, il devra alors renseigner son adresse mail et le code unique lui sera directement envoyé. Il faudra alors rentrer ce code lors de la prochaine visite dans la partie « récupérer mon questionnaire » et l’Utilisateur pourra continuer à renseigner son questionnaire. L’adresse mail de l’Utilisateur ne sera pas utilisée ou conservée par SKEZI.<br /><br />

                    Conformément au Règlement Général sur la Protection des Données (RGPD) n°2016/679 sur la Protection des Données et à la Loi « Informatique et Libertés » (loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, modifiée par la loi n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles), l’Utilisateur peut à tout moment demander à : <br /><br />

                    - consulter vos données personnelles;<br /><br />
                    - les faire modifier;<br /><br />
                    - limiter le traitement de certaines données;<br /><br />

                    Pour cela de la même manière, son numéro de pseudonymisation s’affichera tout au long de sa complétion. Il lui sera propre et sera attaché à ses données. L’Utilisateur pourra choisir d’envoyer ce numéro par mail, comme expliqué précédemment. Seul(e) lui pourra  faire le lien entre son identité et ce numéro. L’Utilisateur devra noter le numéro et le communiquer via l’outil de support s’il souhaite exercer ces droits mentionnés plus haut.<br /><br />

                    La plateforme comprend un support Utilisateur. L’Utilisateur pourra poser des questions relatives à l’étude. Aucune adresse mail ne sera renseignée par l’Utilisateur. Des réponses génériques seront publiées sur la page FAQ de la plateforme.<br /><br />

                    La plateforme ne comprend pas de compte Utilisateur. <br /><br />

                    Toutes les données relatives aux réponses volontairement renseignées par tout Utilisateur sont disponibles sur la Plateforme.<br /><br />

                    La Plateforme est accessible sur ordinateur, téléphone mobile ou tablette.<br /><br />

                    Dans le cadre de la mesure de l’audience sur la Plateforme, Biogen est amené à utiliser des traceurs destinés à la seule mesure de l’audience de la Plateforme (mesure des performances, détection de problèmes de navigation, optimisation des performances techniques ou de son ergonomie, estimation de la puissance des serveurs nécessaires, analyse des contenus consulté), pour son compte exclusif. Les traceurs servent exclusivement à produire des données statistiques.<br /><br />

                    Les traceurs ne sauraient conduire à un recoupement des données avec d’autres traitements ou à ce que les données soient transmises à des tiers, ni à permettre le suivi global de la navigation de la personne utilisant différentes applications ou naviguant sur différents sites web. Les traceurs utilisés sont les suivant :<br /><br />
                </p>
                <table className="cookies-table">
                    <tbody>
                        <tr>
                            <td className="highlight">_pk_id</td>
                            <td>Matomo</td>
                            <td>Statistique</td>
                            <td>Ce cookie est utilisé pour distinguer les humains des robots</td>
                            <td>1 an</td>
                        </tr>
                        <tr>
                            <td className="highlight">_pk_ses</td>
                            <td>Matomo</td>
                            <td>Statistique</td>
                            <td>Utilisé par Piwik Analytics Platform pour suivre les demandes de page du visiteur au cours de la session</td>
                            <td>1 jour</td>
                        </tr>
                    </tbody>
                </table>

                <h2 className="cgu-title">3. Propriété intellectuelle</h2>
                <p className="cgu-paragraph">
                    L’ensemble des éléments constitutifs de la Plateforme (marques, logos, noms commerciaux, noms de domaine, données, métadonnées, dessins, images, photographies, audio ou vidéo, textes, dessins, programmes informatiques, scripts, agencement du site internet, etc.), sans que cette liste soit exhaustive, sont protégés par des droits de propriété intellectuelle. <br /><br />

                    Toute reproduction, représentation, modification, publication, adaptation, téléchargement, transmission de tout ou partie des éléments de la Plateforme, par quelque moyen que ce soit est interdit, sauf autorisation écrite et préalable.<br /><br />

                    Tout accès, modification, ajout, suppression qui porte sur le système de traitement automatisé de données et qui modifie les conditions de publication ou la politique éditoriale est interdit. <br /><br />

                    Les droits de propriété intellectuelle de Biogen restent la propriété exclusive de celui-ci ou, en cas de contenu fourni par des tiers, la propriété de ces derniers si Biogen a obtenu les autorisations nécessaires pour les utiliser sur sa Plateforme.<br /><br />

                    Toute représentation ou exploitation totale ou partielle de tout ou partie des éléments de la Plateforme par quelque personne (physique ou morale) que ce soit, sans l'autorisation expresse de SKEZI et de Biogen, est interdite et constituerait une contrefaçon.
                </p>

                <h2 className="cgu-title">4. Responsabilité</h2>
                <p className="cgu-paragraph">
                L’Utilisateur reconnaît être informé que l’étude proposée se déroule à distance et de façon dématérialisée. L’Utilisateur reconnaît disposer de la compétence, des matériels et moyens nécessaires pour accéder à la Plateforme.<br /><br />

                L’Utilisateur s’engage à utiliser la Plateforme conformément à la finalité décrite dans les présentes Conditions Générales d'Utilisation et aux règlementations en vigueur, et à indiquer uniquement des informations ou contenus conformes à la réalité. L’Utilisateur s’engage à ne pas perturber l’usage normal de la Plateforme. Le manquement à ces obligations ainsi définies peut engendrer la suspension de son accès à la Plateforme et des poursuites judiciaires.<br /><br />

                En aucun cas, Biogen ne saurait être tenu responsable au titre de la responsabilité médicale ou du fait des soins et/ou conseils délivrés dans le cadre du parcours de soin de l’Utilisateur. 
                </p>

                <h2 className="cgu-title">5. Disponibilité</h2>
                <p className="cgu-paragraph">
                Biogen prend les mesures nécessaires au maintien de la continuité et de la qualité de la ou des études proposées sur la Plateforme. L’Utilisateur est informé que la Plateforme est fournie sans aucune garantie de disponibilité et de régularité.<br /><br />

                Biogen s’efforce de rendre la Plateforme accessible 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d’un événement hors du contrôle de Biogen et sous réserve des périodes de maintenance, pannes éventuelles et aléas techniques internes ou extérieurs à Biogen. Aucune responsabilité ne saurait être recherchée à l’encontre de Biogen en cas de perte de disponibilité ou d’accès à la Plateforme, quelle qu’en soit la durée ou la fréquence. <br /><br />

                Biogen se réserve la possibilité de modifier ou d’interrompre temporairement ou de façon permanente, toute ou partie de la Plateforme, sans préavis ni indemnité.  
                </p>

                <h2 className="cgu-title">6. Droit applicable</h2>
                <p className="cgu-paragraph">
                Tout litige en relation avec l'utilisation de la Plateforme est soumis au droit français et relève de la compétence exclusive des juridictions françaises.
                </p>

                <h2 className="cgu-title">7. Modifications des conditions générales</h2>
                <p className="cgu-paragraph">
                Les Conditions Générales peuvent être modifiées à tout moment par Biogen.<br /><br />

                Les modifications entreront en vigueur à compter de leur mise en ligne sur l’Application.
                </p>

                <p className="cgu-paragraph mt-14 sm:mt-20">
                    <u>Date de la dernière mise à jour</u> : 03/10/2023
                </p>
            </div>
        </Layout>
    );
}

export default CGUPage;