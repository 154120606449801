import { Link } from 'react-router-dom';

import './SurveyButton.css';

const SurveyButton = () => {
  return (
    <Link to="/survey" className="survey-button">
      <span>Participer à l'étude</span>
      <i className="fa-solid fa-arrow-right ml-3" />
    </Link>
  );
}

export default SurveyButton;
