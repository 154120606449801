import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import html2canvas from 'html2canvas';
import SupportService from 'services/support.service';

import './SupportForm.css';
import { toast } from 'react-toastify';

export type SendTicketParams = {
  email: string;
  subject: string;
  description: string;
  screenshotUrl?: string;
};

type SupportFormProps = {
  onClose: () => void;
};

const schema = Yup.object({
  subject: Yup.string().required('Veuillez renseigner ce champ'),
  description: Yup.string().required('Veuillez renseigner ce champ'),
  screenshotUrl: Yup.string(),
});

export const SupportForm: FC<SupportFormProps> = ({ onClose }) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [loadingScreenshot, setLoadingScreenshot] = useState<boolean>(false);

  const { handleSubmit, control, reset, setValue } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async ({ ...data }: any) => {
    setDisabled(true);
    try {
      const supportService = new SupportService();
      await supportService.sendTicket({ ...data });
      toast.success('Votre message nous a bien été transmis !');
      setImageUrl('');
      reset({});
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer.');
    }
    setDisabled(false);
  };

  const takeScreenshot = async () => {
    setLoadingScreenshot(true);
    const targetToScreenshot = document.body;
    const screenshotOptions = { foreignObjectRendering: true, useCORS: true };
    const canvas = await html2canvas(targetToScreenshot, screenshotOptions);
    const imageUrl = canvas.toDataURL('image/png');
    setImageUrl(imageUrl);
    setValue('screenshotUrl', imageUrl);
    setLoadingScreenshot(false);
  };

  return (
    <div
      className="contact-form fixed bottom-5 right-4 rounded-l-2xl overflow-x-hidden z-40 border-8 border-r-0 border-custom-dark-blue"
      data-html2canvas-ignore
    >
      <div className="bg-[#005B7F] text-white py-3 px-5 sticky top-0 z-50">
        <div className="flex items-center justify-between mb-5">
          <h4 className="text-custom-dark-blue text-2xl font-semibold">
            Contact
          </h4>
          <button type="button" onClick={onClose}>
            <i className="fa-solid fa-xmark text-custom-dark-blue" />
          </button>
        </div>
        <p>Nous sommes là pour répondre à vos questions. Attention aucune réponse individuelle ne sera faite. Seul des réponses génériques concernant l’étude seront régulièrement publiées sur la <a style={{color: "#6fe5f7"}} href="/faq">FAQ</a>.</p>
      </div>

      <form className="bg-white p-4 flex flex-col items-center justify-center">
        <div className="w-full space-y-3 my-2">
          <Controller
            name="subject"
            defaultValue=""
            control={control}
            render={({ field, fieldState: { error } }: any) => (
              <div className="flex flex-col space-y-1 mt-5">
                <label htmlFor="emailSignup">Sujet</label>
                <input
                  disabled={disabled}
                  type="text"
                  placeholder="Sujet de la demande"
                  className="border px-4 py-2 outline-none focus:ring-2 ring-custom-light-blue rounded-lg placeholder-gray-400 text-gray-700"
                  {...field}
                />
                <span className="text-red-400 text-sm">{error?.message}</span>
              </div>
            )}
          />

          <Controller
            name="description"
            defaultValue=""
            control={control}
            render={({ field, fieldState: { error } }: any) => (
              <div className="flex flex-col space-y-1 mt-5">
                <label htmlFor="emailSignup">Description</label>
                <textarea
                  rows={4}
                  disabled={disabled}
                  placeholder="Description"
                  className="resize-none border px-4 py-2 outline-none focus:ring-2 ring-custom-light-blue rounded-lg placeholder-gray-400 text-gray-700"
                  {...field}
                />
                <span className="text-red-400 text-sm">{error?.message}</span>
              </div>
            )}
          />
        </div>

        <button
          className={`submit-button self-end mt-2 ${
            disabled ? 'cursor-not-allowed bg-gray-500 text-black' : ''
          }`}
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        >
          <span style={{color: "white"}}>Envoyer</span>
          <i className="fa-solid fa-arrow-right ml-3 text-white" />
        </button>
      </form>
    </div>
  );
};
