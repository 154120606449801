import { FC, useState } from 'react';
import { Transition } from '@headlessui/react';
import { SupportForm } from '../SupportForm/SupportForm';

import ChatSVG from '../../../assets/chat.svg';

export const SupportButton: FC = () => {
  const [showContactForm, setShowContactForm] = useState<boolean>(false);

  const openContactForm = () => setShowContactForm(true);
  const closeContactForm = () => setShowContactForm(false);

  return (
    <>
      <button
        className="z-10 fixed bottom-5 right-4 rounded-full w-16 h-16 flex justify-center items-center overflow-hidden bg-[#005B7F] duration-150 hover:bg-[#1B84AD]"
        onClick={openContactForm}
        data-html2canvas-ignore
      >
        <img src={ChatSVG} alt="Icône de support" className="block w-2/3" />
      </button>

      <Transition
        show={showContactForm}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <SupportForm onClose={closeContactForm} />
      </Transition>
    </>
  );
};
