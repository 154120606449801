import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';

import HomePage from './pages/HomePage';
import StudyPage from 'pages/Studypage';
import FaqPage from 'pages/FaqPage';
import SurveyPage from 'pages/SurveyPage';
import CGUPage from 'pages/CGU';

import { SupportButton } from 'components/Support/SupportButton/SupportButton';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import MentionsLegales from 'pages/MentionsLegales';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate replace to="/home" />} />
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/study" element={<StudyPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/cgu" element={<CGUPage />} />
        <Route path="/mentions-légales" element={<MentionsLegales />} />
      </Routes>

      <SupportButton />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
    </Router>
  );
}

export default App;
