import {
  getGeometry,
  isMobile,
  normaliseAngle,
  polarToCartesian,
  smallestSignedAngleBetween,
} from './helpers/utilities';
import {
  DraggedPie,
  DrawNodeProps,
  DrawSegmentProps,
  InitPiechartData,
  PiechartData,
  PiechartObject,
  Target,
} from './types';
import remainderPattern from './assets/line_pattern.svg';

const TAU: number = Math.PI * 2;
const PI: number = Math.PI;

const isMouseEvent = (e: TouchEvent | MouseEvent): e is MouseEvent => {
  return e && 'screenX' in e;
};

const getMouseLocation = (
  evt: TouchEvent | MouseEvent,
  piechart: PiechartObject,
): { x: number; y: number } | undefined => {
  let rect = piechart.canvas && piechart.canvas.getBoundingClientRect();
  if (!rect) {
    return;
  }
  if (isMouseEvent(evt)) {
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top,
    };
  } else {
    return {
      x: evt.targetTouches[0].clientX - rect.left,
      y: evt.targetTouches[0].clientY - rect.top,
    };
  }
};

export const touchStart = (
  event: MouseEvent | TouchEvent,
  piechart: PiechartObject,
) => {
  piechart.draggedPie = getTarget(piechart, getMouseLocation(event, piechart));
  if (piechart.draggedPie) {
    piechart.hoveredIndex = piechart.draggedPie.index;
  }
};

export const touchEnd = (piechart: PiechartObject): void => {
  if (piechart.draggedPie) {
    piechart.draggedPie = null;
    draw(piechart);
  }
};

export const touchMove = (
  event: MouseEvent | TouchEvent,
  piechart: PiechartObject,
): void => {
  let dragLocation = getMouseLocation(event, piechart);
  if (!dragLocation) {
    return;
  }

  if (!piechart.draggedPie) {
    let hoveredTarget = getTarget(piechart, dragLocation);
    if (hoveredTarget) {
      piechart.hoveredIndex = hoveredTarget.index;
      draw(piechart);
    } else if (piechart.hoveredIndex != -1) {
      piechart.hoveredIndex = -1;
      draw(piechart);
    }
    return;
  }

  let draggedPie = piechart.draggedPie;

  let dx = dragLocation.x - draggedPie.centerX;
  let dy = dragLocation.y - draggedPie.centerY;

  // Get angle of grabbed target from centre of pie
  let newAngle = Math.atan2(dy, dx) - draggedPie.angleOffset;

  let proportion = getProportionFromAngle(piechart, newAngle);
  if (proportion === undefined) {
    return;
  }
  piechart.moveAngle(draggedPie.index - 1, proportion);
};

/*
 * Drawing angle segments.
 */
export const drawSegment = (props: DrawSegmentProps): void => {
  let {
    arcSize,
    centerX,
    centerY,
    color,
    context,
    label,
    proportion,
    radius,
    startingAngle,
  } = props;

  // Draw segment
  context.save();
  let endingAngle = startingAngle + arcSize;
  context.beginPath();
  context.moveTo(centerX, centerY);
  context.arc(centerX, centerY, radius, startingAngle, endingAngle, false);
  context.closePath();
  context.strokeStyle = 'white';
  context.stroke();

  if (label !== 'remainder') {
    context.fillStyle = color.background || '#f5f5f5';
    context.fill();
  }

  context.restore();

  // Draw proportion label on top for desktop
  if (label !== 'remainder') {
    let fontSize;
    if (isMobile()) {
      fontSize = Math.floor(context.canvas.height / 16);
    } else {
      fontSize = Math.floor(context.canvas.height / 22);
    }
    let textPosition = polarToCartesian(
      centerX,
      centerY,
      radius / 1.45,
      startingAngle + arcSize / 2,
    );

    context.save();
    context.textAlign = 'center';
    context.fillStyle = color.text || 'black';
    context.font = fontSize + 'pt Helvetica';
    if (proportion * 100 >= 6) {
      //text margin applied for the first and last sixth
      let marginX: number = 0;
      let marginY: number = 0;
      let isFirstSixth: boolean = startingAngle < (5 * -PI) / 6;
      let isLastSixth: boolean = startingAngle > -PI / 6;
      if (isFirstSixth || isLastSixth) {
        marginY = 5;
        if (isFirstSixth) {
          marginX = -5;
        } else if (isLastSixth) {
          marginX = 5;
        }
      }

      context.fillText(
        `${Math.round(proportion * 100)}%`,
        textPosition.x + marginX,
        textPosition.y + marginY,
      );
    }
    context.restore();
  }
};

/*
 * Drawing draggable nodes.
 */
export const drawNode = (props: DrawNodeProps): void => {
  let { context, nodeX, nodeY, centerX, centerY, hover, index } = props;
  let nodeRadius: number = 0;
  //Responsive node radius
  if (isMobile()) {
    nodeRadius = hover ? 10 : 7;
  } else {
    nodeRadius = hover ? 11 : 8;
  }

  // Drawing lines from center to nodes
  if (index > 0) {
    context.save();
    context.beginPath();
    context.moveTo(centerX, centerY);
    context.strokeStyle = '#d9dfe9';
    context.lineWidth = 4;
    context.lineTo(nodeX, nodeY);
    context.stroke();
    context.restore();
  }

  //Drawing the shadow box
  context.save();
  context.translate(nodeX, nodeY);
  context.beginPath();
  context.fillStyle = 'white';
  context.shadowColor = 'gray';
  context.shadowBlur = 8;
  context.arc(0, 0, nodeRadius, 0, TAU, true);
  context.fill();
  context.restore();

  //Drawing the circle and border
  context.save();
  context.translate(nodeX, nodeY);
  context.beginPath();
  context.fillStyle = 'white';
  context.arc(0, 0, nodeRadius, 0, TAU, true);
  context.fill();

  context.strokeStyle = '#d9dfe9';
  context.lineWidth = 2;
  context.stroke();
  context.restore();
};

/*
 * Draws the piechart
 */
export const draw = (piechart: PiechartObject): void => {
  let context = piechart.context;
  if (context === null) {
    return;
  }

  let canvas = piechart.canvas;
  if (!canvas) {
    return;
  }
  context.clearRect(0, 0, canvas.width, canvas.height);

  let geometry = getGeometry(piechart);
  if (!geometry) {
    return;
  }

  //Drawing remainder
  context.save();
  context.beginPath();
  context.arc(
    geometry.centerX,
    geometry.centerY,
    geometry.radius,
    0,
    Math.PI,
    true,
  );
  const img = new Image();
  img.src = remainderPattern;
  const pattern = context.createPattern(img, 'repeat');
  context.fillStyle = pattern ? pattern : '#E3E3E3';
  context.fill();
  context.restore();

  let visibleSegments = getVisibleSegments(piechart);

  // Drawing segments
  for (let i = 0; i < visibleSegments.length; i++) {
    let currentSegment = visibleSegments[i];

    drawSegment({
      arcSize: currentSegment.arcSize,
      color: currentSegment.color,
      centerX: geometry.centerX,
      centerY: geometry.centerY,
      context,
      label: currentSegment.label,
      proportion: currentSegment.proportion,
      radius: geometry.radius,
      startingAngle: currentSegment.angle,
    });
  }

  // Draw drag nodes on top
  for (let i = 0; i < visibleSegments.length; i++) {
    //First node should not show
    if (i === 0) {
      continue;
    }
    //Responsive node offset (fixed + variable parts)
    let reverseIndex: number = visibleSegments.length - 1 - i;
    let nodeOffset: number = isMobile() ? 8 : 20;
    let uniqueOffset: number = isMobile()
      ? reverseIndex * 8
      : reverseIndex * 10;

    let angle: number = visibleSegments[i].angle;
    let nodePosition = polarToCartesian(
      geometry.centerX,
      geometry.centerY,
      geometry.radius + nodeOffset + uniqueOffset,
      angle,
    );

    drawNode({
      centerX: geometry.centerX,
      centerY: geometry.centerY,
      context,
      hover: i == piechart.hoveredIndex,
      index: i,
      nodeX: nodePosition.x,
      nodeY: nodePosition.y,
    });
  }

  //Adding 0 and 100% text underneath gauge
  context.save();
  context.fillStyle = 'black';
  context.textAlign = 'center';
  let fontSize: number = Math.floor(context.canvas.width / 35);
  context.font = fontSize + 'pt Helvetica';
  let marginX: number = geometry.radius / 4;
  let marginY: number = canvas.height * 0.075;
  context.fillText(
    '0%',
    geometry.centerX - geometry.radius + marginX,
    geometry.centerY + marginY,
  );
  context.fillText(
    '100%',
    geometry.centerX + geometry.radius - marginX,
    geometry.centerY + marginY,
  );
  context.restore();

  //Drawing cover center
  context.save();
  context.beginPath();
  context.fillStyle = '#FFFFFF';
  let coverRadius = canvas.height * 0.18;
  context.arc(
    geometry.centerX,
    geometry.centerY + 2,
    coverRadius,
    0,
    Math.PI,
    true,
  );
  context.fill();
  context.restore();

  //Update legend inputs on change
  piechart.onchange(piechart);
};

/*
 * Calculating proportion from new angle.
 */
export const getProportionFromAngle = (
  piechart: PiechartObject,
  newAngle: number,
): number | void => {
  if (!piechart.draggedPie) {
    return;
  }

  let draggedPie: DraggedPie | null = piechart.draggedPie;

  //To drag angle even if mouse has passed the base axis.
  if (newAngle > 0) {
    if (newAngle > PI / 2) {
      let previousPiechartData: PiechartData[] = piechart.data.slice(
        0,
        draggedPie.index,
      );
      let visibleAngles: number[] = previousPiechartData
        .filter((data) => data.arcSize > 0)
        .map((data) => data.angle);
      let previousAngle: number | undefined = visibleAngles.slice(-1)[0];
      newAngle = previousAngle || -PI;
    } else {
      let nextPiechartData: PiechartData[] = piechart.data.slice(
        draggedPie.index + 1,
        piechart.data.length - 1,
      );
      let visibleAngles: number[] = nextPiechartData
        .filter((data) => data.arcSize > 0)
        .map((data) => data.angle);
      let nextAngle: number | undefined = visibleAngles[0];
      newAngle = nextAngle || 0;
    }
  }

  // Get starting angle of the target
  let startingAngle: number = draggedPie.startingAngles[draggedPie.index];

  // Get diff from grabbed target start (as -pi to +pi)
  let angleDragDistance: number = smallestSignedAngleBetween(
    newAngle,
    startingAngle,
  );

  // Get previous diff
  let previousDragDistance: number = draggedPie.angleDragDistance;

  // Determines whether we go clockwise or anticlockwise
  let rotationDirection: number = previousDragDistance > 0 ? 1 : -1;

  // Reverse the direction if we have done over 180 in either direction
  let sameDirection: boolean =
    previousDragDistance > 0 == angleDragDistance > 0;
  let greaterThanHalf: boolean =
    Math.abs(previousDragDistance - angleDragDistance) > Math.PI;

  if (greaterThanHalf && !sameDirection) {
    // Reverse the angle
    angleDragDistance = (PI - Math.abs(angleDragDistance)) * rotationDirection;
  } else {
    rotationDirection = angleDragDistance > 0 ? 1 : -1;
  }

  draggedPie.angleDragDistance = angleDragDistance;

  for (let i = 1; i < piechart.data.length; i++) {
    // Get angle from target start to this angle
    let startingAngleToNonDragged: number = smallestSignedAngleBetween(
      draggedPie.startingAngles[i],
      startingAngle,
    );

    // If angle is in the wrong direction then it should actually be OVER 180
    if (startingAngleToNonDragged * rotationDirection < 0) {
      startingAngleToNonDragged =
        (startingAngleToNonDragged * rotationDirection + TAU) *
        rotationDirection;
    }

    //Preventing from going lower than previous angle
    if (
      startingAngleToNonDragged < 0 &&
      angleDragDistance < startingAngleToNonDragged
    ) {
      return;
    }
  }

  let segmentStartingAngle: number | undefined =
    piechart.data[draggedPie.index - 1]?.angle;
  if (segmentStartingAngle === undefined) {
    return;
  }

  let arcSize: number = newAngle - segmentStartingAngle;
  let proportion: number = arcSize / PI;
  proportion = +proportion.toFixed(2);

  return proportion;
};

/*
 * Generates angle data from proportions (array of objects with proportion, format
 */
export const generateDataFromProportions = (
  piechartData: InitPiechartData[] | PiechartData[],
): PiechartData[] => {
  // sum of proportions
  let proportions: number[] = piechartData.map((data) => data.proportion);
  let total = proportions.reduce(function (a: number, v: number) {
    return a + v;
  }, 0);
  // begin at PI
  let currentAngle = -PI;

  let lastSegmentIndex = 0;
  for (let i = 0; i < piechartData.length - 1; i++) {
    if (piechartData[i].proportion) {
      lastSegmentIndex = i;
    }
  }

  // use the proportions to reconstruct angles
  return piechartData.map(function (
    v: InitPiechartData | PiechartData,
    i: number,
  ) {
    let currentProportion = v.proportion;
    let arcSize = (PI * currentProportion) / total;
    let data: PiechartData = {
      angle: currentAngle,
      arcSize,
      color: v.color,
      description: v.description,
      format: v.format,
      index: i,
      label: v.label,
      proportion: v.proportion,
    };

    currentAngle = normaliseAngle(currentAngle + arcSize);

    //To display the first segment when it's 100%
    if (piechartData[0].proportion === 1) {
      if (currentAngle === -PI) {
        currentAngle = 0;
      }
    }

    //To avoid the last node to be drawn at the start when it's 100%
    if (piechartData[piechartData.length - 1].proportion === 0) {
      if (i >= lastSegmentIndex) {
        currentAngle = 0;
      }
    }
    return data;
  });
};

/*
 * Returns visible segments
 */
export const getVisibleSegments = (
  piechart: PiechartObject,
): PiechartData[] => {
  // Collect data for visible segments
  let visibleSegments: PiechartData[] | [] = new Array();

  for (let i = 0; i < piechart.data.length; i += 1) {
    let piechartData = piechart.data[i];
    let startingAngle = piechartData.angle;
    if (startingAngle === undefined) {
      continue;
    }

    // Get arcSize
    let foundNextAngle = false;
    for (let j = 1; j < piechart.data.length; j += 1) {
      let nextAngleIndex = (i + j) % piechart.data.length;
      let nextAngle = piechart.data[nextAngleIndex].angle;

      let arcSize = nextAngle - startingAngle;

      if (i === 0) {
        arcSize = nextAngle;
      } else if (i === piechart.data.length - 1) {
        arcSize = 0 - startingAngle;
      }

      if (arcSize <= 0) {
        arcSize += PI;
      }

      if (piechartData.proportion === 0) {
        arcSize = 0;
      }

      visibleSegments.push({
        arcSize: arcSize,
        angle: startingAngle,
        label: piechartData.label,
        proportion: piechartData.proportion,
        description: piechartData.description,
        color: piechartData.color,
        format: piechartData.format,
        index: i,
      });

      foundNextAngle = true;
      break;
    }

    // Only one segment
    if (!foundNextAngle) {
      visibleSegments.push({
        arcSize: PI,
        angle: startingAngle,
        label: piechartData.label,
        proportion: piechartData.proportion,
        description: piechartData.description,
        color: piechartData.color,
        format: piechartData.format,
        index: i,
      });
      break;
    }
  }
  return visibleSegments;
};

/*
 * Returns a segment to drag if given a close enough location to node
 */
const getTarget = (
  piechart: PiechartObject,
  targetLocation: { x: number; y: number } | undefined,
): Target | null => {
  if (!targetLocation) {
    return null;
  }
  let geometry = getGeometry(piechart);
  if (!geometry) {
    return null;
  }

  let startingAngles: number[] = [];

  let closest: {
    index: number;
    distance: number;
    angle: number | null;
  } = {
    index: -1,
    distance: 9999999,
    angle: null,
  };

  for (let i = 0; i < piechart.data.length; i += 1) {
    let angle: number = piechart.data[i].angle;
    startingAngles.push(angle);

    let dx = targetLocation.x - geometry.centerX;
    let dy = targetLocation.y - geometry.centerY;
    let trueGrabbedAngle = Math.atan2(dy, dx);

    let reverseIndex: number = piechart.data.length - 1 - i;
    let nodeLocation = polarToCartesian(
      0,
      0,
      geometry.radius + 20 + reverseIndex * 10,
      angle,
    );
    let distance = Math.hypot(nodeLocation.y - dy, nodeLocation.x - dx);

    if (distance < closest.distance) {
      closest.index = i;
      closest.distance = distance;
      closest.angle = trueGrabbedAngle;
    }
  }

  if (closest.angle && closest.distance < 30) {
    let angleOffset = smallestSignedAngleBetween(
      closest.angle,
      startingAngles[closest.index],
    );

    let result = {
      index: closest.index,
      angleOffset: angleOffset,
      centerX: geometry.centerX,
      centerY: geometry.centerY,
      startingAngles: startingAngles,
      angleDragDistance: 0,
    };

    return result;
  } else {
    return null;
  }
};

export const adjustCanvasSize = (piechart: PiechartObject): void => {
  let canvas = piechart.canvas;
  if (canvas === null) {
    return;
  }

  let piechartContainer = document.querySelector(
    '.piechart-container',
  ) as HTMLElement;
  let containerWidth: number | undefined = piechartContainer?.offsetWidth;

  if (window.innerWidth < 1100) {
    canvas.width = containerWidth || window.innerWidth;
  } else {
    canvas.width = canvas.height = 600;
  }

  canvas.height = canvas.width / 1.5;

  draw(piechart);
};
