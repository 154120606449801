import {
  DraggedPie,
  InitPiechartData,
  PiechartData,
  PiechartObject,
  PieChartSetup,
} from './types';
import {
  adjustCanvasSize,
  draw,
  generateDataFromProportions,
  getVisibleSegments,
  touchStart,
  touchMove,
  touchEnd,
} from './methods';
import { isTouchDevice } from './helpers/utilities';

export class DraggablePiechart implements PiechartObject {
  canvas: HTMLCanvasElement | null;
  radius: number;
  context: CanvasRenderingContext2D | null;
  data: PiechartData[];
  draggedPie: DraggedPie | null;
  hoveredIndex: number;
  minAngle: number;
  onchange: (piechart: PiechartObject) => void;

  constructor() {
    this.canvas = null;
    this.context = null;
    this.data = [];
    this.draggedPie = null;
    this.hoveredIndex = -1;
    this.minAngle = 0;
    this.onchange = () => {};
    this.radius = 0.8;
    this.data = [];
  }

  /*
   * Setting canvas, proportions and binding mouse and touch events.
   */
  init(setup: PieChartSetup): void {
    this.canvas = setup.canvas;
    this.context = setup.canvas ? setup.canvas.getContext('2d') : null;
    if (setup.onchange) {
      this.onchange = setup.onchange;
    }
    if (setup.radius) {
      this.radius = setup.radius;
    }
    if (setup.proportions) {
      if (
        !setup.proportions.some(
          (proportion) => proportion.label === 'remainder'
        )
      ) {
        let totalProportion: number = setup.proportions.reduce(
          (acc: number, val: InitPiechartData) => acc + val.proportion,
          0
        );
        let remainderProportion: number =
          totalProportion > 1 ? 0 : 1 - totalProportion;

        setup.proportions.push({
          label: 'remainder',
          proportion: remainderProportion,
          description: 'remainder',
          format: {
            label: 'remainder',
          },
          color: {
            background: '#E3E3E3',
            text: '#679ACB',
          },
        });
      }
      this.data = generateDataFromProportions(setup.proportions);
    }

    if (!this.canvas || !this.context) {
      console.log('Error: DraggablePiechart needs an html5 canvas.');
      return;
    }

    //Bind appropriate events
    let piechart: PiechartObject = this;

    adjustCanvasSize(piechart);

    //Touch events
    this.canvas.addEventListener('touchstart', (e: TouchEvent) => {
      touchStart(e, piechart);
      e.preventDefault();
    });
    this.canvas.addEventListener('touchmove', (e: TouchEvent) => {
      touchMove(e, piechart);
      e.preventDefault();
    });
    document.addEventListener('touchend', () => touchEnd(piechart));

    //Mouse events
    this.canvas.addEventListener('mousedown', (e: MouseEvent) =>
      touchStart(e, piechart)
    );
    this.canvas.addEventListener('mousemove', (e: MouseEvent) =>
      touchMove(e, piechart)
    );
    document.addEventListener('mouseup', () => touchEnd(piechart));

    if (!isTouchDevice()) {
      window.addEventListener('resize', () => adjustCanvasSize(piechart));
    }

    draw(piechart);
  }

  /*
   * Move angle specified by index: i, to a specific proportion.
   */
  moveAngle(i: number, proportion: number): void {
    let piechart: PiechartObject = this;
    let currentProportion: number = piechart.data[i].proportion;
    let proportionDelta: number = proportion - currentProportion;
    let newProportions: PiechartData[] = [];

    let remainderProportion: number =
      piechart.data[piechart.data.length - 1].proportion;
    let newRemainderProportion: number = remainderProportion;

    let skip: boolean = false;
    let deducted: number = 0;

    if (currentProportion === proportion) {
      //No changes
      skip = true;
    } else if (proportion < 0) {
      //preventing negative proportions
      skip = true;
    } else if (currentProportion > proportion) {
      //decreasing segment proportion
      newRemainderProportion =
        remainderProportion + (currentProportion - proportion);
    } else {
      //increasing segment proportion

      if (remainderProportion > 0) {
        let remainderLeft: number = remainderProportion - proportionDelta;

        if (remainderLeft < 0) {
          //proportion requested is larger than remainder size:
          //set maximum available in this case
          deducted = Math.abs(remainderLeft);
          newRemainderProportion = 0;
        } else {
          newRemainderProportion = remainderLeft;
        }
      } else {
        //No remainder available to allocate
        skip = true;
      }
    }

    //Recalculating all segments proportion
    newProportions = piechart.data.map((item: PiechartData, index: number) => {
      if (index === i) {
        return {
          ...item,
          proportion: +(proportion - deducted).toFixed(2),
        };
      } else if (index === piechart.data.length - 1) {
        return {
          ...item,
          proportion: +newRemainderProportion.toFixed(2),
        };
      } else {
        return {
          ...item,
          proportion: +item.proportion.toFixed(2),
        };
      }
    });

    if (!skip) {
      piechart.data = generateDataFromProportions(newProportions);
      draw(piechart);
    }

    //Updating legends
    piechart.onchange(piechart);
  }

  /*
   * Gets all percentages for each slice
   */
  getAllSliceSizeProportions(): number[] {
    let piechart: PiechartObject = this;
    let visibleSegments = getVisibleSegments(piechart);
    let proportions: number[] = new Array(piechart.data.length);
    for (let i = 0; i < piechart.data.length; i += 1) {
      for (let j = 0; j < visibleSegments.length; j += 1) {
        let arcSize: number = visibleSegments[j].arcSize;
        if (visibleSegments[j].index === i) {
          proportions[i] = arcSize / Math.PI;
        }
      }
    }

    return proportions;
  }
}
