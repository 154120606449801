import { Children, ReactNode, useState } from 'react';

import './Carousel.css';

const Carousel = (props: {children: ReactNode}) => {
  const [active, setActive] = useState<number>(1);
  const count = Children.count(props.children);
  
  return (
    <div className='carousel lg:hidden'>
      {active > 0 && <button className='nav left' onClick={() => setActive(i => i - 1)}><i className="fa-solid fa-chevron-left" /></button>}
      {Children.map(props.children, (child, i) => (
        <div className='card-container' style={{
            // @ts-ignore
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            'pointer-events': active === i ? 'auto' : 'none',
            'opacity': Math.abs(active - i) >= 2 ? '0' : '1',
            'display': Math.abs(active - i) > 2 ? 'none' : 'block',
          }}>
          {child}
        </div>
      ))}
      {active < count - 1 && <button className='nav right' onClick={() => setActive(i => i + 1)}><i className="fa-solid fa-chevron-right" /></button>}
    </div>
  );
};

export default Carousel;
