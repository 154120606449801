import { get, post } from "./fetcher";

export default class ResponseService {

  async loadSurvey() {
    return await get('/survey');
  }

  async addResponse(responses: Object) {
    return await post(`/addResponses`, responses);
  }

  async addPartialResponse(responses: Object) {
    return await post(`/addPartialResponses`, responses);
  }

  async loadResponses(code: string) {
    return await get(`/responses/${code}`);
  }

  async sendMail(data: any) {
    return await post('/mail', data);
  }
}
