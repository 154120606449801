import Layout from 'components/Layout/Layout';

import '../styles/CGU.css';

const MentionsLegales = () => {
    return (
        <Layout title="Mentions Légales">
            <div className="cgu-paragraph px-5 font-arimo">
                <h1 className="text-xl mt-40 md:text-3xl font-bold mb-10 md:mb-16">Mentions légales New PerSEPtion</h1>
                    <p className="relative mb-20">
                        Conformément aux dispositions de l’article 6 III 1° de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous vous informons que :<br /><br />
                        L’Application est éditée par SAS SKEZI au Capital social de 10 225,00 € immatriculée au RCS de Annecy sous le numéro d’identification unique B 892 223 603, dont le siège social est situé aux Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.<br /><br />
                        La plateforme New PerSEPtion est la propriété de l’entreprise Biogen France SAS, inscrite au répertoire SIREN sous le numéro 398410126, dont le siège social est 1 PASSERELLE DES REFLETS 92400 COURBEVOIE.<br /><br />
                        Le directeur de publication est Béatrice Baciotti, Directrice Medical Biogen France.
                    </p>
                    <p className="relative">
                        L’hébergement est assuré par OVH, 2 rue Kellermann – 59100 Roubaix – France Numéro de téléphone de l’hébergeur : 1007 (service client).
                    </p>
            </div>
        </Layout>
    );
}

export default MentionsLegales;