import Layout from '../components/Layout/Layout';
import SurveyButton from 'components/Buttons/SurveyButton';

import Illustration1 from '../assets/HOME/RESPONSIVE/Resp_IllustrationHOME_1.svg';
import Illustration1_Desktop from '../assets/HOME/IllustrationHOME_Section1.svg';
import Illustration2 from '../assets/HOME/Illustration2.svg';
import Patrick_Vermesch from '../assets/Patrick_Vermesch.png';
import Johnathan_Ciron from '../assets/Johnathan_Ciron.png';
import Caroline_Bensa from '../assets/Caroline_Bensa.png';
import Sandrine_Wiertlewski from '../assets/Sandrine_Wiertlewski.png';
import AFSEP from '../assets/AFSEP.png';
import NotreSclerose from '../assets/NotreSclérose.png';
import ArrowSVG from '../assets/arrow_bottom.svg';

const HomePage = () => {
  return (
    <Layout title="Accueil">
      <section className="px-4 md:px-20 lg:pl-32 relative">
        <img src={Illustration1} alt="Illustration 1" className="absolute top-10 right-0 lg:hidden" />
        <img src={Illustration1_Desktop} alt="Illustration 1" className="absolute top-10 right-0 w-[250px] xl:w-[350px] 2xl:w-[500px] 2xl:top-0 hidden lg:block" />
        <h1 className="font-bold text-3xl md:text-5xl pt-52 relative z-10 w-3/4 md:w-auto lg:max-w-2xl">
          New<br className="md:hidden" /> PER<span className="text-outline">SEP</span>TION
        </h1>
        <hr className="border-0 bg-dark-pink h-7 rounded-full w-64 md:w-[500px] transform -translate-x-10 -translate-y-5 mb-5 lg:max-w-2xl" />
        <p className="font-bold mb-5 w-3/4 md:w-auto text-justify lg:max-w-2xl">
          New PerSEPtion, la perception des patients sur les traitements à base d'anticorps monoclonaux de la SEP-RR, au-delà de leur efficacité clinique.
        </p>
        <p className="text-justify lg:max-w-2xl">
          New PerSEPtion est une étude sur la sclérose en plaques,
          en ligne et anonyme qui vous <b>prendra moins de 30 minutes.</b> <br />
          Vous pourrez faire <b>une pause en enregistrant</b> vos réponses
          en cours de route et y revenir plus tard, si vous le désirez. <br />
          Vous avez également la possibilité de <b>télécharger</b> le questionnaire vide,
          ou complété. <b>N’hésitez pas à vous faire aider d’un proche ou d’un aidant.</b>
        </p>
        <div className="mt-16">
          <SurveyButton />
        </div>
      </section>

      <section className='flex justify-center items-center flex-col'>
        <br />
        <p>En savoir plus</p>
        <div>
          <img src={ArrowSVG} alt="Flèche descendante" className="block" />
        </div>
      </section>

      <section className="mt-32 md:flex">
        <div className="bg-dark-blue text-white mr-4 rounded-r-3xl p-4 md:p-10 relative z-10 md:m-0 md:flex-1 md:rounded-r-[60px] md:flex md:flex-col md:items-center md:justify-center md:space-y-32">
          <div className="w-2/3">
            <h1 className="text-xl font-bold mb-5 md:text-4xl border-rounded-left relative text-pastel-blue">Pourquoi et comment participer ?</h1>
            <p className="text-justify md:hidden">
              Cette étude en ligne est anonyme et vous prendra moins de 30 min.
              Vous pourrez vous interrompre en enregistrant vos réponses au fur
              et à mesure et y revenir plus tard, si vous le souhaitez.
            </p>
          </div>
          <div className="block absolute bottom-0 right-4 w-[28%] md:relative md:w-1/2 md:max-w-xs">
            <img src={Illustration2} alt="Illustration point d'interrogation" />
            <hr className="hidden md:block bg-dark-pink h-20 rounded-full border-0 w-[4500px] transform -translate-x-[4200px] 2xl:-translate-x-[4000px]" />
          </div>
        </div>
        <div className="md:flex-1">
          <ul className="bg-pastel-blue pt-44 -mt-48 md:m-0 md:p-10 p-5 ml-6 rounded-l-3xl md:-ml-16 md:mt-28 md:pl-28">
            <li>
              <div className="flex items-center space-x-3 mb-5 mt-10">
                <i className="fa-regular fa-circle-dot text-xl text-dark-pink" />
                <h2 className="font-bold text-xl">Thème de l’étude</h2>
              </div>
              <p className="text-justify ml-9">
                L’objectif de cette étude est de comprendre votre perception des traitements
                de la sclérose en plaques au-delà de leur efficacité clinique. Elle s’intéressera
                également à d’autres dimensions, comme les effets de la sclérose en plaques sur
                votre qualité de vie et votre fatigue, le fardeau lié au traitement et enfin le
                processus de décision partagée du traitement.
              </p>
            </li>
            <li>
              <div className="flex items-center space-x-3 mb-5 mt-10">
                <i className="fa-regular fa-circle-dot text-xl text-dark-pink" />
                <h2 className="font-bold text-xl">Comment participer à l’étude ?</h2>
              </div>
              <p className="text-justify ml-9">
                Vous pouvez participer à l’étude New perSEPtion en répondant à un questionnaire en
                ligne qui vous permettra de vous exprimer librement sur ces sujets.
              </p>
            </li>
            <li>
              <div className="flex items-center space-x-3 mb-5 mt-10">
                <i className="fa-regular fa-circle-dot text-xl text-dark-pink" />
                <h2 className="font-bold text-xl">Qui peut participer ?</h2>
              </div>
              <p className="text-justify ml-9">
                Si vous êtes majeur(e), que vous avez une sclérose en plaques pour laquelle
                vous avez déjà reçu un traitement (Kesimpta, Ocrevus , Tysabri intra-veineuse
                ou sous-cutané), et que vous êtes suivi par un neurologue en France. Votre
                témoignage est important pour répondre à ces questions.
              </p>
            </li>
          </ul>
          <div className="flex justify-center mt-20">
            <SurveyButton />
          </div>
        </div>
      </section>

      <section className="-mb-4">
        <h1 className="font-bold text-[20px] mt-32 relative z-10 px-4 md:text-3xl md:pl-32">
          Découvrez le comité scientifique
        </h1>
        <hr className="border-0 bg-custom-blue h-3 rounded-full w-11/12 lg:w-3/4 xl:w-2/3 2xl:w-1/2 -mt-3 mb-16 md:h-5 md:mt-2 md:transform md:-translate-x-56" />
        <p className="hidden md:block px-32 mb-16">
          Cette étude est supervisée par un comité scientifique composé de neurologues
          et d'associations de patients : Notre Sclérose et l'AFSEP.
        </p>
        <div className="flex">
          <div className="bg-pastel-blue rounded-tr-[60px] flex flex-col items-end p-5 flex-1 pb-32 md:flex-row md:justify-end md:items-center md:px-12 md:pt-12">
            <img src={Patrick_Vermesch} alt="Pr Patrick Vermersch" className="object-cover w-24 h-24 mt-5 rounded-lg md:order-2 md:w-40 md:h-40 md:m-0" />
            <div className="p-3 text-center max-w-xs rounded-lg bg-white w-11/12 self-start -mt-5 md:order-1 md:m-0 md:w-auto md:self-auto md:mr-10 lf:mr-20">
              <h3 className="font-bold mb-2">Pr Patrick Vermersch</h3>
              <span className="text-dark-pink block mb-2 text-sm">Investigateur principal de l'étude</span>
              <p className="text-sm">
                Neurologue et vice-président pour la recherche,
                dans les domaines des sciences de la vie et de
                la santé à l’université de Lille.
              </p>
            </div>
          </div>
          <div className="bg-custom-blue rounded-tl-[60px] flex flex-col items-start p-5 flex-1 pb-32 md:flex-row md:justify-start md:items-center md:px-12 md:pt-12">
            <img src={Johnathan_Ciron} alt="Dr Jonathan Ciron" className="object-cover w-24 h-24 mt-5 rounded-lg md:w-40 md:h-40 md:m-0" />
            <div className="p-3 max-w-xs text-center rounded-lg bg-white w-11/12 self-end -mt-5 md:m-0 md:w-auto md:self-auto md:ml-10 lg:ml-20">
              <h3 className="font-bold mb-2">Dr Jonathan Ciron</h3>
              <p className="text-sm">Neurologue, CHU de Toulouse </p>
            </div>
          </div>
        </div>

        <div className="flex -mt-24">
          <div className="bg-dark-pink rounded-tr-[60px] flex flex-col items-end p-5 flex-1 pb-32 md:flex-row md:justify-end md:items-center md:px-12 md:pt-12">
            <img src={Caroline_Bensa} alt="Dr Caroline Bensa" className="object-cover w-24 h-24 mt-5 rounded-lg md:order-2 md:w-40 md:h-40 md:m-0" />
            <div className="p-3 max-w-xs text-center rounded-lg bg-white w-11/12 self-start -mt-5 md:m-0 md:w-auto md:self-auto md:mr-10 lf:mr-20">
              <h3 className="font-bold mb-2">Dr Caroline Bensa</h3>
              <p className="text-sm">Neurologue, Hôpital Fondation Adolphe de Rothschild</p>
            </div>
          </div>
          <div className="bg-dark-blue rounded-tl-[60px] flex flex-col items-start p-5 flex-1 pb-32 md:flex-row md:justify-start md:items-center md:px-12 md:pt-12">
            <img src={Sandrine_Wiertlewski} alt="Dr Sandrine Wiertlewski" className="object-cover w-24 h-24 mt-5 rounded-lg md:w-40 md:h-40 md:m-0" />
            <div className="p-3 max-w-xs text-center rounded-lg bg-white w-11/12 self-end -mt-5 md:m-0 md:w-auto md:self-auto md:ml-10 lg:ml-20">
              <h3 className="font-bold mb-2">Dr Sandrine Wiertlewski</h3>
              <p className="text-sm">Neurologue, CHU de Nantes </p>
            </div>
          </div>
        </div>

        <div className="flex -mt-24">
          <div className="bg-light-pink rounded-tr-[60px] flex flex-col items-end p-5 flex-1 pb-20 md:flex-row md:justify-end md:items-center md:px-12 md:pt-12">
            <img src={AFSEP} alt="Association française des sclérosés en plaques" className="object-cover w-28 h-24 mt-5 rounded-lg md:order-2 md:w-44 md:h-36 md:m-0" />
            <div className="p-3 max-w-xs text-center rounded-lg bg-white w-11/12 self-start -mt-5 md:m-0 md:w-auto md:self-auto md:mr-10 lf:mr-20">
              <h3 className="font-bold mb-2">Association française des sclérosés en plaques</h3>
            </div>
          </div>
          <div className="bg-pastel-blue rounded-tl-[60px] flex flex-col items-start p-5 flex-1 pb-20 md:flex-row md:justify-start md:items-center md:px-12 md:pt-12">
            <img src={NotreSclerose} alt="Association Notre Sclérose" className="object-cover w-28 h-24 mt-5 rounded-lg md:w-44 md:h-36 md:m-0" />
            <div className="p-3 max-w-xs text-center rounded-lg bg-white w-11/12 self-end -mt-5 md:m-0 md:w-auto md:self-auto md:ml-10 lg:ml-20">
              <h3 className="font-bold mb-2">Association Notre Sclérose</h3>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default HomePage;