import { Link } from 'react-router-dom'
import LogoBiogen from '../../assets/logo_biogen.svg'

const Footer = () => {
	return (
		<footer className='flex flex-col space-y-10 md:flex-row md:space-y-0 items-center justify-between bg-custom-blue rounded-t-2xl p-4 z-10 absolute bottom-0 w-full'>
			<div className='bg-white p-2 rounded-md'>
				<img src={LogoBiogen} alt='Logo Biogen' className='block h-6' />
			</div>
			<ul className='flex flex-col space-y-4 text-white px-5 text-center text-sm'>
				<li>
					<b>Promoteur</b> : Biogen France SAS <b>Investigateur principal</b> : Pr Patrick Vermersch{' '}
					<b>Titre</b> : New PerSEPtion, la perception des patients sur les traitements à base
					d'anticorps monoclonaux de la SEP-RR, au-delà de leur efficacité clinique. <b>ID RCB</b> :
					2023-A01349-36
				</li>
				<li>Novembre 2023 - Biogen-225433</li>
			</ul>
			<ul className='flex items-center space-x-5 text-white'>
				<li>
					<Link to='/cgu'>CGU</Link>
				</li>
				<li>
					<Link to='/mentions-légales'>Mentions Légales</Link>
				</li>
			</ul>
		</footer>
	)
}

export default Footer
