import { QuestionTextModel, Serializer } from 'survey-core';
import { DraggablePiechart } from './src/piechart';
import './src/styles/piechart.css';
import { PieChartSetup, PiechartData, PiechartObject } from './src/types';
import { getLegendHTML, piechartHTML } from './src/views/piechartTemplate';

export const Piechart = {
  //the widget name. It should be unique and written in lowercase.
  name: 'piechart',
  //This title will be displayed on SurveyJS Creator toolbox
  title: 'Pie Chart',
  //Icon that displays on SurveyJS Creator toolbox
  //You can leave this property empty to show the default icon
  iconName: '',
  //It is a required function for a custom type widget, that user can see on toolbox
  //Commonly you check here, that all scripts/css files are loaded on the page
  widgetIsLoaded: function () {
    return true; //We do not have external scripts
    //return typeof $ == "function"; //example of checking on loading jQuery
  },
  //Applies for a question that has our own type
  isFit: function (question: any) {
    return question.getType() == 'piechart';
  },
  init() {
    //Register a new type using the empty question as the base.
    //@ts-ignore
    Serializer.addClass('piechart', [], null, 'empty');

    Serializer.addProperty('piechart', {
      name: 'elements:items',
      /* onGetValue: function (obj) {
        return obj.elements;
      },
      onSetValue: function (obj, value) {
        obj.elements = value;
      }, */
      properties: [
        { name: 'label:text', default: '' },
        { name: 'proportion:number', default: 0 },
        { name: 'description:text', default: '' },
        {
          name: 'format:object',
          properties: [{ name: 'label:text', default: '' }],
        },
        {
          name: 'color:object',
          properties: [
            { name: 'background:text', default: '' },
            { name: 'text:text', default: '' },
          ],
        },
      ],
    });
  },
  //We do not need default rendering here.
  //SurveyJS will render this template by default
  htmlTemplate: piechartHTML,
  //Our element will be rendered base on template.
  //We do not need to do anything here
  afterRender: function (question: QuestionTextModel, element: HTMLElement) {
    setTimeout(() => {
      const randomId = String(
        Date.now() + Math.floor(Math.random() * 1_000_000),
      );

      const savedData = question.data.getValue(question.name);
      if (savedData) {
        question.elements.forEach((piechartData: any) => {
          if (savedData[piechartData.label]) {
            piechartData.proportion = savedData[piechartData.label].proportion;
          }
        });
      }

      const legendContainerId = `legend-container-${randomId}`;
      element.children[0].children[1].id = legendContainerId;

      const canvasId = `piechart-${randomId}`;
      element.children[0].children[0].id = canvasId;

      const canvas = document.getElementById(canvasId) as HTMLCanvasElement;

      if (!canvas) {
        console.log('Widget not loaded properly.');
        return;
      }

      let setup: PieChartSetup = {
        canvas,
        radius: 0.7,
        proportions: question.elements,
        onchange: (piechart: PiechartObject) => {
          setLegends(piechart, randomId);
          let response: { [key: string]: { proportion: number } } = {};
          let results: PiechartData[] = piechart.data.filter(
            (data) => data.label !== 'remainder',
          );
          results.forEach(
            (data) => (response[data.label] = { proportion: data.proportion }),
          );
          question.data.setValue(question.name, response, true, true);
        },
      };

      let piechart = new DraggablePiechart();
      piechart.init(setup);

      setLegends(piechart, randomId);
    });
  },
};

const updateAngle = (
  e: Event,
  index: number,
  piechart: PiechartObject,
  randomId: string,
): void => {
  const target = e.target as HTMLInputElement;
  //Empty input defaults to 0
  if (!target.value) {
    target.value = '0';
  }

  //Sanitizing input
  let regex: RegExp = /^[-0-9]*?(\.[0-9])*$/;
  if (!regex.test(target.value)) {
    setLegends(piechart, randomId);
    return;
  }

  //Preventing input lower than 0 and higher than 100
  let percentage = parseInt(target.value);
  if (percentage > 100 || percentage < 0) {
    if (percentage > 100) {
      percentage = 100;
    } else {
      percentage = 0;
    }
  }

  let proportion: number = percentage / 100;
  piechart.moveAngle(index, proportion);
};

const setLegends = (piechart: PiechartObject, randomId: string) => {
  let legends: string[] = [];
  const legendContainer: HTMLElement | null = document.getElementById(
    `legend-container-${randomId}`,
  );
  let piechartDataList: PiechartData[] = piechart.data;

  //Generating legend elements from template.
  for (let i = 0; i < piechartDataList.length - 1; i++) {
    let piechartData: PiechartData = piechartDataList[i];
    let legend: string = getLegendHTML(
      i.toString(),
      piechartData.color.background,
      piechartData.description,
      Math.round(piechartData.proportion * 100),
      randomId,
    );
    legends.push(legend);
  }

  if (legendContainer) {
    legendContainer.innerHTML = legends.join('');
  }

  //Adding the input event listener for angle update.
  let inputs: HTMLCollectionOf<Element> = document.getElementsByClassName(
    `legend-item__input-${randomId}`,
  );
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('change', (e: Event) =>
      updateAngle(e, i, piechart, randomId),
    );
  }
};
