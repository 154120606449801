import axios from 'axios';

const URL = process.env.REACT_APP_SERVER_URL;

export async function get(path: string, params?: any) {
  const { data } = await axios.get(URL + path, params);
  return data;
};

export async function post(path: string, params: any) {
  const { data } = await axios.post(URL + path, params);
  return data;
};

export async function put(path: string, params: any) {
  const { data } = await axios.put(URL + path, params);
  return data;
};
