/* eslint-disable react-hooks/exhaustive-deps */
import Footer from 'components/Footer/Footer';
import { ReactNode, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../Header/Header';

interface LayoutProps {
  children: ReactNode,
  title?: string
}

const Layout = (props: LayoutProps) => {

  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    document.title = props.title ? 'New Perseption - ' + props.title : 'New Perseption';
  }, []);
  
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
}

export default Layout;