import { Dispatch, Fragment, SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SubmitButton from 'components/Buttons/SubmitButton';
import Loader from 'components/Loader/Loader';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  codeRecovery: string;
  setCodeRecovery: Dispatch<SetStateAction<string>>;
  submitRecovery: any;
  loadingRecovery: boolean;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  submitSaving?: any;
  loadingSaving: boolean;
  sendMail: any;
}

const ModalSaveSurvey = (props: ModalProps) => {

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-11/12 max-h-[700px] overflow-auto max-w-md transform rounded-2xl bg-white ring-[20px] ring-light-pink p-6 text-left align-middle shadow-xl transition-all custom-scrollbar">
                <Dialog.Title
                  as="h2"
                  className="text-xl text-center font-medium leading-6 text-gray-900 mb-8"
                >
                  <span className="text-dark-pink">Récupérer</span> mon formulaire pour le poursuivre.
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-md text-gray-700 mb-5">
                    Vous avez déjà fait la demande de continuer plus tard et souhaitez récupérer vos données, indiquez le code de sauvegarde que vous avez reçu par mail ci-dessous.
                  </p>
                </div>

                <form onSubmit={props.submitRecovery}>
                  <div className="flex flex-col space-y-2 mt-7">
                    <label htmlFor="code_save" className="text-dark-pink">Votre code de sauvegarde</label>
                    <input
                      onChange={e => props.setCodeRecovery(e.target.value)}
                      value={props.codeRecovery}
                      type="text"
                      name="code_save"
                      id="code_save"
                      className="border border-dark-pink px-4 py-2 outline-none focus:ring-2 ring-light-pink rounded"
                    />
                  </div>

                  <div className="mt-10">
                    {
                      props.loadingRecovery ? (
                        <Loader />
                      ) : (
                        <SubmitButton text="Récupérer" />
                      )
                    }
                  </div>
                </form>

                <div className="mt-20 text-right">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-500 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 duration-300"
                    onClick={props.onClose}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ModalSaveSurvey;
