import Layout from 'components/Layout/Layout';

import Illustration1 from '../assets/ETUDE/RESPONSIVE/Resp_IllustrationETUDE_section1.svg';
import Illustration2 from '../assets/ETUDE/RESPONSIVE/Resp_IllustrationETUDE_section2.svg';
import Illustration3 from '../assets/ETUDE/RESPONSIVE/Resp_IllustrationETUDE_section3.svg';
import Illustration4 from '../assets/ETUDE/RESPONSIVE/Resp_IllustrationETUDE_section4.svg';
import Illustration5 from '../assets/ETUDE/RESPONSIVE/Resp_IllustrationETUDE_section5.svg';

import Illustration1_Desktop from '../assets/ETUDE/IllustrationETUDE_Section1.svg';
import Illustration2_Desktop from '../assets/ETUDE/IllustrationETUDE_Section2.svg';
import Illustration5_Desktop from '../assets/ETUDE/IllustrationETUDE_Section5.svg';

const StudyPage = () => {
  return (
    <Layout title="Notre étude">
      <section className="overflow-hidden pb-20">
        <div className="pl-14 md:pl-28">
          <h1 className="font-bold text-3xl md:text-5xl mt-44 relative z-10 uppercase">
            Notre étude
          </h1>
          <hr className="border-0 transform -translate-x-3 bg-light-pink h-5 rounded-full w-[2500px] md:mt-2" />
        </div>
        <div className="md:flex items-center justify-between">
          <div className="hidden h-96 w-96 bg-dark-blue transform -translate-x-10 lg:-translate-x-14 xl:-translate-x-16 2xl:-translate-x-20 rounded-[60px] md:block" />
          <div>
            <h2 className="pl-4 font-bold text-2xl md:text-3xl mt-20 relative z-10">
              Quels sont les <br /> objectifs de l'étude ?
            </h2>
            <hr className="border-0 transform -translate-x-56 bg-pastel-blue h-5 rounded-full w-[500px] md:w-96 md:transform-none" />
            <div className="flex items-center">
              <p className="mt-10 px-4 w-5/6 text-justify">
                L’objectif de cette étude est de porter votre voix
                auprès des communautés de soignants pour leur permettre
                de mieux comprendre vos attentes en termes de prise en
                charge et votre perception des traitements. Elle va
                permettre de mettre en lumière des dimensions comme :
              </p>
              <div className="h-48 w-1/6 bg-dark-blue rounded-3xl transform translate-x-8 md:hidden" />
            </div>
          </div>
          <ul className="mt-20 mx-4 bg-pastel-blue px-5 py-10 pb-36 relative rounded-3xl flex flex-col space-y-6 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-10 lg:mr-16 xl:mr-28">
            <img src={Illustration1} alt="Illustration 1" className="absolute -bottom-10 -right-36 md:hidden" />
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Comprendre votre perception sur les traitements de la sclérose en plaques, au-delà de leur efficacité clinique.
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Caractériser le fardeau du traitement depuis votre point de vue.
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Caractériser le processus de décision partagée du traitement.
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Évaluer votre fatigue.
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Évaluer votre qualité de vie.
            </li>
          </ul>
        </div>
      </section>
      <section className="bg-pastel-blue py-10 mb-20 mt-10 md:px-28 relative">
        <img src={Illustration1_Desktop} alt="Illustration 1" className="hidden lg:block absolute -top-72 right-0 w-[400px] xl:w-[520px] xl:-top-[350px]" />
        <h2 className="pl-4 font-bold text-2xl md:text-3xl relative z-10">
          Qui peut participer ?
        </h2>
        <hr className="border-0 transform -translate-x-56 -translate-y-3 bg-light-pink h-5 rounded-full w-[500px] md:w-[550px]" />
        <div className="px-4">
          <p className="mt-6">Vous pouvez participer à cette étude si :</p>
          <ul className="mt-7 flex flex-col space-y-4">
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Vous avez 18 ans ou plus;
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Vous êtes atteint d'une sclérose en plaques;
              <img src={Illustration2} alt="Illustration 2" className="w-1/2 lg:hidden max-w-[280px] ml-auto" />
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Vous êtes suivi par un neurologue en France;
            </li>
            <li className="flex flex-col">
              <div>
                <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
                Vous recevez l'un des traitements suivants :
              </div>
              <ul className="list-disc px-10 mt-3">
                <li>Kesimpta® (Ofatumumab)</li>
                <li>Ocrevus® (Ocrelizumab)</li>
                <li>Tysabri® en administration intraveineuse (IV) (Natalizumab)</li>
                <li>Tysabri® en administration sous-cutanée (SC) (Natalizumab)</li>
              </ul>
            </li>
            <li className="flex">
              <i className="fa-solid fa-circle mt-[5px] text-dark-pink text-xs mr-3" />
              Vous recevez ce traitement depuis plus de 6 mois.
            </li>
          </ul>
          <p className="mt-6">Cela est issu du protocole de l’étude développé avec le Comite scientifique et approuvé par le Comité d’éthique ou Comité de Protection des Personnes CPP Nord Ouest II en date du 18/10/2023</p>
        </div>
        <img src={Illustration2_Desktop} alt="Illustration 2" className="hidden lg:block absolute bottom-0 right-0 w-96" />
      </section>
      <section className="relative mt-52 sm:mt-64 md:mt-80 max-w-3xl md:max-w-[900px] mx-auto">
        <div className="relative z-10 bg-pastel-blue border-[12px] border-dark-pink rounded-2xl ml-4 mr-16 px-4 py-6 md:w-[400px] lg:w-[500px]">
          <h2 className="text-2xl font-bold mb-4">En quoi consiste l'étude ?</h2>
          <p className="text-justify">
            Vous allez remplir un questionnaire dans lequel vous pourrez vous
            exprimer librement sur vos attentes par rapport à votre maladie
            et à vos traitements. Nous allons également vous poser des
            questions sur les effets de la sclérose en plaques sur votre
            qualité de vie et votre fatigue. Enfin, il y aura quelques
            questions sur vous et votre sclérose en plaques.
            <br /><br />
            Vous n’avez pas à créer de compte, ni à renseigner de données
            identifiantes. Nous estimons que cela vous prendra moins de 30 minutes.
            <br /><br />
            Vous pourrez compléter le questionnaire en plusieurs fois. Un code
            à usage unique vous sera alors envoyé par e-mail pour vous permettre
            de reprendre le questionnaire là où vous l’avez quitté. Votre
            adresse e-mail ne sera pas enregistrée.
          </p>
        </div>
        <div className="bg-pastel-blue rounded-2xl w-3/4 md:w-[500px] h-56 sm:h-72 md:h-96 border-[12px] border-custom-blue absolute -top-32 sm:-top-44 right-4 overflow-hidden">
          <img src={Illustration3} alt="Illustration 3" className="object-cover w-[110%] h-[110%]" />
        </div>
      </section>
      <section className="mt-20 md:mt-40 overflow-hidden">
        <div className="flex items-center mb-16 md:transform md:-translate-x-44">
            <div className="h-16 w-12 bg-custom-blue rounded-xl transform -translate-x-8 md:w-96 md:rounded-full md:h-6" />
            <h2 className="text-xl md:text-3xl font-bold -ml-6 px-4">
              Que dois-je savoir avant de participer ?
            </h2>
        </div>
        <div className="md:flex">
          <div className="flex md:flex-1 justify-center items-center relative">
            <img src={Illustration4} alt="Illustration 4" className="bg-pastel-blue p-3 rounded-2xl shadow-md md:p-10 md:w-2/3 max-w-sm relative z-20" />
            <div className="bg-pastel-blue rounded-xl absolute h-16 w-11/12 -left-5 z-10 md:h-32 md:w-full" />
          </div>
          <ul className="flex md:flex-1 flex-col space-y-7 mt-16">
            <li className="p-10 bg-pastel-blue rounded-l-xl relative bg-opacity-30 ml-8 border-left-pastel-blue">
              <h3 className="mb-6 text-lg font-semibold">
                <i className="fa-solid fa-lock text-xl text-dark-blue mr-5" />
                <span>Données personnelles :</span>
              </h3>
              <ul className="list-disc ml-8 text-justify flex flex-col space-y-4">
                <li>
                  Aucune donnée personnelle permettant directement de vous identifier ne sera collectée.
                </li>
                <li>
                  Votre participation est colontaire et vous pouvez vous retirer de l'étude en tout temps.
                </li>
              </ul>
            </li>
            <li className="p-10 bg-light-pink rounded-l-xl relative bg-opacity-30 ml-8 border-left-light-pink">
              <h3 className="mb-6 text-lg font-semibold">
                <i className="fa-regular fa-envelope text-xl text-dark-blue mr-5" />
                <span>Nous contacter :</span>
              </h3>
              <ul className="list-disc ml-8 text-justify flex flex-col space-y-4">
                <li>
                  Vous pouvez contacter le responsable de l'étude pour lui faire part
                  de vos questions et commentaires. Nous serons heureux de vous fournir
                  de plus amples informations.
                </li>
              </ul>
            </li>
            <li className="p-10 bg-dark-pink rounded-l-xl relative bg-opacity-30 ml-8 border-left-dark-pink">
              <h3 className="mb-6 text-lg font-semibold">
                <i className="fa-solid fa-earth-europe text-xl text-dark-blue mr-5" />
                <span>Collecte et partage :</span>
              </h3>
              <ul className="list-disc ml-8 text-justify flex flex-col space-y-4">
                <li>
                  Toutes les données collectées seront sauvegardées sur un serveur sécurisé en France et effacées après 2 ans. Les résultats de l'étude seront publiés dans une revue scientifique.
                </li>
                <li>
                  Vous pourrez télécharger/imprimer le questionnaire pour votre usage personnel et pour le partager à votre convenance avec votre médecin ou votre entourage.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section className="mt-20 overflow-hidden md:flex md:space-x-10 md:items-center lg:space-x-20 xl:space-x-44">
        <img src={Illustration5_Desktop} alt="Illustration 5" className="hidden md:block w-[350px] lg:w-1/3 self-end" />
        <div>
          <h2 className="text-2xl font-bold px-4 md:pr-10 mb-10">
            Comment puis-je être informé des résultats de l'étude ?
          </h2>
          <p className="px-4 md:pr-10 text-justify relative mb-36">
            Les résultats de cette étude seront communiqués dans des congrès nationaux et internationaux, dans des publications, auprès de neurologues et d'associations de patients.
            <br /><br />
            <span className="block w-2/3 md:w-full">
              Un webinaire de restitution sera organisé via les associations de patients pour discuter et présenter les résultats globaux de l'étude.
            </span>
            <img src={Illustration5} alt="Illustration 5" className="absolute -bottom-44 -right-24 md:hidden" />
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default StudyPage;