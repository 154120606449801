import { PiechartObject, PieChartSetup, Target } from '../types';

export const mod = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

export const normaliseAngle = (angle: number) => {
  return mod(angle + Math.PI, Math.PI) - Math.PI;
};

export const polarToCartesian = (
  x1: number,
  y1: number,
  length: number,
  angle: number
) => {
  var x2 = x1 + length * Math.cos(angle);
  var y2 = y1 + length * Math.sin(angle);

  return { x: x2, y: y2 };
};

export const smallestSignedAngleBetween = (target: number, source: number) => {
  return Math.atan2(Math.sin(target - source), Math.cos(target - source));
};

export const extend = (defaults: PieChartSetup | {}, setup: PieChartSetup) => {
  let setupArguments = [defaults, setup];
  let result: PieChartSetup | { [key: string]: any } = {};

  for (let i = 1; i < setupArguments.length; i++) {
    let setArgument: { [key: string]: any } = setupArguments[i];
    if (!setArgument) {
      continue;
    }

    for (let key in setArgument) {
      if (setArgument.hasOwnProperty(key)) {
        result[key] = setArgument[key];
      }
    }
  }

  return result;
};

/*
 * Gets the geometry of the pie chart in the canvas
 */
export const getGeometry = (
  piechart: PiechartObject
): { centerX: number; centerY: number; radius: number } | null => {
  let rect = piechart.canvas && piechart.canvas.getBoundingClientRect();
  if (!rect) {
    return null;
  }
  //defines the center of the half-circle in the canvas
  let centerX = Math.floor(rect.width / 2);
  let centerY = Math.floor(rect.height / 1.3);
  return {
    centerX: centerX,
    centerY: centerY,
    radius: Math.min(centerX, centerY) * piechart.radius,
  };
};

export const isMobile = (): boolean => {
  return window.innerWidth < 450;
};

export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};
