import './SubmitButton.css';

const SubmitButton = (props: { text: string }) => {
  return (
    <button className="submit-button" type="submit">
      <span className="text-white">{props.text}</span>
      <i className="fa-solid fa-arrow-right ml-3 text-white" />
    </button>
  );
}

export default SubmitButton;
