export const piechartHTML = `
        <div class="piechart-container">
            <canvas class="piechart"></canvas>
            <div class="legend-container"></div>
        </div>
    `;

export const getLegendHTML = (
  key: string,
  color: string,
  description: string,
  value: number,
  randomId: string,
) => {
  return `
        <div class="legend-item" key="${key}" >
            <div class="legend-item__header" >
                <div class="legend-item__color" style="background-color: ${color};"></div>
                
                <div class="legend-item__input-container">
                    <span>%</span>
                    <input class="legend-item__input legend-item__input-${randomId}" value="${value}"></input>
                </div>
            </div>

            <p class="legend-item__description">${description}</p>
        </div>
    `;
};
